import React from 'react'; 
import { Loader2 } from 'lucide-react';
import { Modal } from 'react-bootstrap';

const ServerSideSortableDataTable = ({columns, data, paginationInfo, setPaginationInfo, sortConfig, updateSortConfig, setPerPage, isLoading}) => {

    const [overflowTextModal, setOverflowTextModal] = React.useState(false);
    const [overflowText, setOverflowText] = React.useState('');
    function showOverFlowTextModal(info){ 
        setOverflowTextModal(true); 
        setOverflowText(info);
    }

     const {perPage, totalPages, currentPage, totalRecords} = paginationInfo;
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                verticalAlign: 'middle',
                // padding: '4px 20px 4px 3px',
                textAlign: 'left',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
                verticalAlign: 'middle',
                fontSize: '16px',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
                verticalAlign: 'middle',
                fontSize: '16px',
            },
        },
    };


    const renderSortIcon = (column) => {
        const selector = column.selector; 
        if(!selector) return;

        if (sortConfig.field !== null  && sortConfig.field === selector) {
            return sortConfig.direction === 'asc' ? <i className="fa fa-sort-asc" aria-hidden="true"></i> : <i className="fa fa-sort-desc" aria-hidden="true"></i>;
        } else {
            return <i className="fa fa-sort" aria-hidden="true"></i>;
        }
    };


  return (
    <div className='table-responsive BackendDrivenDataTable tw-relative mt-3'>   

        {
            isLoading && (
            <div className="tw-absolute tw-inset-0 tw-bg-white/60 tw-flex tw-items-center tw-justify-center tw-z-10">
                <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                    <Loader2 className="tw-h-8 tw-w-8 tw-animate-spin tw-text-primary" />
                    <p className="tw-text-sm tw-text-gray-700">Loading Data...</p>
                </div>
            </div>
        )}

        <table className='table table-striped mb-0'>
            <thead>
                {columns.map((column, index) => (
                    <th key={index}
                        className="tw-break-keep tw-whitespace-pre-wrap border"
                        style={{
                            cursor: column.sortable ? 'pointer' : 'default',
                            ...customStyles.headCells.style,
                        }}
                        onClick={e => updateSortConfig(column.selector, sortConfig.direction === 'asc' ? 'desc' : 'asc', column.sortable)}
                    >
                        {column.name}
                        {column.sortable && renderSortIcon(column)}
                    </th>
                ))}
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {columns.map((column, index) => (
                            column.selector ?
                            (
                              (typeof row[column.selector] === 'string' && row[column.selector].length > 40) ? <td key={index} style={{textAlign: 'left', paddingLeft: '18px'}}>{row[column.selector].slice(0, 40) + '...'} <span style={{color: 'blue', cursor: 'pointer'}} onClick={e => showOverFlowTextModal(row[column.selector])}>Read More</span></td>:
                              <td key={index} style={{textAlign: 'left', paddingLeft: '18px'}}>{row[column.selector]}</td>
                            ):
                            (
                                column.cell ? <td key={index} style={{textAlign: 'left', paddingLeft: '18px'}}> {column.cell(row)} </td> : null
                            )
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>

        <div className="pagination-container">
                    <div className="rows-per-page">
                        <label>Rows per page: </label>
                        <select value={perPage} onChange={e => setPerPage(e.target.value)} >
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                        </select>
                    </div>

                    <div className="records-info">
                        { totalRecords > 0 && `${currentPage*perPage - perPage + 1}-${Math.min(currentPage*perPage, totalRecords)} of ${totalRecords} records`}
                    </div>

                    {totalRecords > 0 && (
                        <div className="pagination-controls">
                            <button onClick={e => setPaginationInfo({ ...paginationInfo, currentPage: 1 })} disabled={currentPage === 1}>
                                <i className='bx bx-first-page'></i>
                            </button>
                            <button onClick={e => setPaginationInfo({ ...paginationInfo, currentPage: currentPage - 1 })} disabled={currentPage === 1}>
                                <i className='bx bx-chevron-left'></i>
                            </button>
                            <button  onClick={e => setPaginationInfo({ ...paginationInfo, currentPage: currentPage + 1 })}  disabled={currentPage === totalPages}>
                                <i className='bx bx-chevron-right'></i>
                            </button>
                            <button onClick={e => setPaginationInfo({ ...paginationInfo, currentPage: totalPages })} disabled={currentPage === totalPages}>
                                <i className='bx bx-last-page'></i>
                            </button>
                        </div>
                    )}
                </div>


                <Modal id="callCloseWarning" show={overflowTextModal} onHide={() => setOverflowTextModal(false)} centered size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton center>
                </Modal.Header>
                <Modal.Body>
                <div className='row text-center'>
                   <p style={{ 
                     textAlign: 'left',
                     paddingLeft: '18px' 
                   }}>{overflowText}</p>
                </div>
                </Modal.Body>
        </Modal>

    </div>
  )
}

export default ServerSideSortableDataTable;