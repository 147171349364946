import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import "./Dashboard.css"
import { fetchReportTypes, fetchNewReports, fetchHealthlockerFileUrl } from "../components/services/Service";
import axios from "axios";
import CalendarDatePicker from '../../componenents/CalendarDatePicker';
import moment from 'moment';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import ViewAssessmentV1 from '../components/healthLocker/ViewAssessmentV1';
import ViewAssessmentV2 from '../components/healthLocker/ViewAssessmentV2';
import './AppointmentsReport.css';

const ContentNewReports = ({ onUserClick }) => {
    const perPageRecs = 20;
    const [membershipNumber, setMembershipNumber] = useState("");
    const [reportTypeId, setReportTypeId] = useState("");
    const [requestStartDate, setRequestStartDate] = useState(moment().subtract(6, 'days'));
    const [requestEndDate, setRequestEndDate] = useState(moment());
    const [fileUrlId, setFileUrlId] = useState("");
    const [reports, setReports] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showViewHRAModal, setShowViewHRAModal] = useState(false);
    const [hraJson, setHraJson] = useState({});
    const [memberBasicInfo, setMemberBasicInfo] = useState({})

    const [showAssessmentModal, setShowAssessmentModal] = useState(false);
    const [assessmentJson, setAssessmentJson] = useState({});


    useEffect(() => {
        getReportTypes();
        getNewReports(membershipNumber, reportTypeId, formatDate(requestStartDate), formatDate(requestEndDate), pageNumber);
    }, [])

    useEffect(() => {
        if (pageNumber > 1)
            getNewReports(membershipNumber, reportTypeId, formatDate(requestStartDate), formatDate(requestEndDate), pageNumber);
    }, [pageNumber])

    const observer = useRef();
    const lastUserElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore]);


    const getHealthlockerFileUrl = async (fileId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthlockerFileUrl(fileId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    window.open(response.data.data.fileUrl, '_blank', 'noopener,noreferrer');
                    setFileUrlId("");
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getReportTypes = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchReportTypes(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setReportTypes(response.data.data.reportTypes);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleDateChange = ({ start, end }) => {
        //setPageNumber(1);
        setRequestStartDate(start);
        setRequestEndDate(end);
        getNewReports(membershipNumber, reportTypeId, formatDate(start), formatDate(end), 1);
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const getNewReports = async (membershipNumber, reportTypeId, requestStartDate, requestEndDate, pageNumber) => {
        setReports([]);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchNewReports(membershipNumber, reportTypeId, requestStartDate, requestEndDate, pageNumber, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setReports(prevReports => {
                        const newReports = (response.data.data.files).filter(reports =>
                            !prevReports.some(existingReports => existingReports.fileId === reports.fileId)
                        );
                        return [...prevReports, ...newReports];
                    });

                    if (response.data.data.files.length == perPageRecs) setHasMore(true);
                    else setHasMore(false);
                    setLoading(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const viewAssessment = (json, version) => {
        let filteredJson = {};
        if (version === "1.0") {
            filteredJson = {
                ...json,
                medications: json.medications.filter(row => !isEmptyRow(row)),
                examinations: json.examinations.filter(row => !isEmptyRow(row)),
                labResults: json.labResults.filter(row => !isEmptyRow(row)),
                imagingResults: json.imagingResults.filter(row => !isEmptyRow(row)),
                version: version
            };
        } else {
            filteredJson = {
                ...json,
                medications: json.medications.filter(row => !isEmptyRow(row)),
                labResults: json.labResults.filter(row => !isEmptyRow(row)),
                imagingResults: json.imagingResults.filter(row => !isEmptyRow(row)),
                version: version
            };
        }


        //setAssessmentJson(json);
        setAssessmentJson(filteredJson);
        setShowAssessmentModal(true)
    }

    const viewHRA = (json) => {
        setHraJson(json);
        setShowViewHRAModal(true)
    }
    const isEmptyRow = (row) => {
        return Object.values(row).every(value => value === '' || value === '0');
    };
    return <>
        <div className="table-responsive" style={{ height: "480px", overflowX: "hidden", overflowY: "scroll" }}>
            <table className='table table-striped'>
                <thead style={{ position: "sticky", top: "0", backgroundColor: '#fff' }}>
                    <tr>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight: "500", fontSize: "14px" }}>Membership #</th>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight: "500", fontSize: "14px" }}>Request Type</th>
                    </tr>
                    <tr className='mb-2'>
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }}>
                            <input type="input" name="membershipNumber" value={membershipNumber} onChange={(e) => {
                                if (!/^\d*\.?\d*$/.test(e.target.value)) {
                                    return false;
                                }
                                //setPageNumber(1);
                                setMembershipNumber(e.target.value);
                                getNewReports(e.target.value, reportTypeId, formatDate(requestStartDate), formatDate(requestEndDate), 1);
                            }
                            } className='form-control control-input-ui-dashboard' />
                        </td>
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }}>
                            <select name="reportTypeId" className="form-select select-input-ui-dashboard" value={reportTypeId} onChange={(e) => {
                                //setPageNumber(1);
                                setReportTypeId(e.target.value);
                                getNewReports(membershipNumber, e.target.value, formatDate(requestStartDate), formatDate(requestEndDate), 1);
                            }}>
                                <option value="">Select</option>
                                <option value="all">All</option>
                                {
                                    reportTypes.map((item, index) => {
                                        return <option value={item.reportTypeId}>{item.reportType}</option>
                                    })
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight: "500", fontSize: "14px" }}>Request Date</th>
                        <th colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none", fontWeight: "500", fontSize: "14px" }}></th>
                    </tr>
                    <tr className='mb-2'>

                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }} id="CalendarDatePicker">
                            <CalendarDatePicker startDate={requestStartDate} endDate={requestEndDate} onDateChange={handleDateChange} />
                        </td>
                        <td colSpan="2" style={{ backgroundColor: "transparent", padding: "2px", border: "none" }}>

                        </td>
                    </tr>
                    {
                        reports.length <= 0 &&
                        <tr><td colSpan={4} style={{ border: "none" }}>No records found</td></tr>
                    }

                </thead>
                <tbody >
                    {
                        reports.map((item, index) => {
                            if (reports.length === index + 1) {
                                return <tr key={index} ref={lastUserElementRef} style={{ fontSize: "14px" }}>
                                    <td className='py-2'>{item.memberName}</td>
                                    <td>{item.reportType}</td>
                                    <td>{item.date}</td>
                                    <td>
                                        <Link
                                            to={'/members/' + encodeURIComponent(item.userId, "UTF-8")}
                                            className='' onClick={() => onUserClick({ userId: item.userId, name: item.memberName })}
                                        >
                                            <i className="fa fa-user" aria-hidden="true" style={{ marginRight: '10px', cursor: 'pointer' }}></i></Link>

                                        {
                                            item.reportType == "hra" &&
                                            <i style={{ cursor: 'pointer' }} className={`fa fa-eye ${fileUrlId == item.fileId ? "hide" : ""}`} aria-hidden="true" onClick={() => viewHRA(item.json, item.type)}></i>
                                        }
                                        {
                                            item.reportType == "assessment" &&
                                            <i style={{ cursor: 'pointer' }} className={`fa fa-eye ${fileUrlId == item.fileId ? "hide" : ""}`} aria-hidden="true" onClick={() => viewAssessment(item.json, item.version)} ></i>
                                        }
                                        {
                                            item.reportType != "hra" && item.reportType != "assessment" &&
                                            <i style={{ cursor: 'pointer' }} className={`fa fa-eye ${fileUrlId == item.fileId ? "hide" : ""}`} aria-hidden="true" onClick={() => {
                                                setFileUrlId(item.fileId);
                                                getHealthlockerFileUrl(item.fileId);
                                            }}></i>
                                        }

                                        {
                                            fileUrlId === item.fileId &&
                                            <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "25px" }} alt="" /></span>
                                        }
                                    </td>
                                </tr>
                            } else {
                                return <tr key={index} style={{ fontSize: "14px" }}>
                                    <td className='py-2'>{item.memberName}</td>
                                    <td>{item.reportType}</td>
                                    <td>{item.date}</td>
                                    <td>
                                        <Link
                                            to={'/members/' + encodeURIComponent(item.userId, "UTF-8")}
                                            className='' onClick={() => onUserClick({ userId: item.userId, name: item.memberName })}
                                        >
                                            <i className="fa fa-user" aria-hidden="true" style={{ marginRight: '10px', cursor: 'pointer' }}></i>
                                        </Link>

                                        {
                                            item.reportType == "hra" &&
                                            <i style={{ cursor: 'pointer' }} className={`fa fa-eye ${fileUrlId == item.fileId ? "hide" : ""}`} aria-hidden="true" onClick={() => viewHRA(item.json, item.type)}></i>
                                        }
                                        {
                                            item.reportType == "assessment" &&
                                            <i style={{ cursor: 'pointer' }} className={`fa fa-eye ${fileUrlId == item.fileId ? "hide" : ""}`} aria-hidden="true" onClick={() => viewAssessment(item.json, item.version)} ></i>
                                        }
                                        {
                                            item.reportType != "hra" && item.reportType != "assessment" &&
                                            <i style={{ cursor: 'pointer' }} className={`fa fa-eye ${fileUrlId == item.fileId ? "hide" : ""}`} aria-hidden="true" onClick={() => {
                                                setFileUrlId(item.fileId);
                                                getHealthlockerFileUrl(item.fileId);
                                            }}></i>
                                        }

                                        {
                                            fileUrlId === item.fileId &&
                                            <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "25px" }} alt="" /></span>
                                        }
                                    </td>
                                </tr>
                            }
                        })
                    }
                </tbody>
            </table>
        </div>
        <Modal id="viewHRA" show={showViewHRAModal} onHide={() => {
            setShowViewHRAModal(false);
            setHraJson({})
        }} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
            <Modal.Header closeButton>
                <h1 className='modal-title'>HRA</h1>
            </Modal.Header>
            <Modal.Body>
                {
                    Object.entries(hraJson).map(([key, field]) => {
                        const { value, question, category, score } = field;
                        return (
                            <div key={key} className='mt-3'>
                                {
                                    (key == "bmi") ?
                                        <>
                                            <b><label>BMI:</label></b>
                                            <span>{value}</span>
                                        </>
                                        : ""
                                }
                                {key == "answer_1" && question == undefined && <><b><label>Input weight in kgs</label></b></>}
                                {key == "answer_2" && question == undefined && <><b><label>Input height in cms</label></b></>}
                                {question != "" && <><b><label>{question}</label></b></>}
                                {key.includes("_specified") != "" && <><b><label>Specified:</label></b></>}
                                {

                                    (Array.isArray(value)) ?
                                        <span><br />{value.join(', ')}<br /></span>
                                        : <><span><br />{(key != "bmi") ? value : ""}</span><br /></>
                                }
                            </div>
                        );
                    })
                }
            </Modal.Body >
        </Modal >

        <Modal id="viewAssessment" show={showAssessmentModal} onHide={() => {
            setShowAssessmentModal(false);
            setAssessmentJson({});
        }} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
            <Modal.Header closeButton>
                <h1 className='modal-title'>Assessment</h1>
            </Modal.Header>
            <Modal.Body>
                {
                    assessmentJson && assessmentJson.version == "1.0" &&
                    <ViewAssessmentV1 assessmentJson={assessmentJson} />
                }
                {
                    assessmentJson && assessmentJson.version == "1.1" &&
                    <ViewAssessmentV2 assessmentJson={assessmentJson} memberBasicInfo={memberBasicInfo} />
                }

            </Modal.Body>
        </Modal>
    </>
}

export default ContentNewReports;
