import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { fetch_all_members_reenroll_past } from '../../../services/met/Service';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';

function PastRequestReenroll() {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [membersEnrollList, setMembersEnrollList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [curentRequestNote, setCurrentRequestNote] = useState("");

    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("reEnrollRequestedDate");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    const navigate = useNavigate();

    /*    useEffect(() => {
            setFilteredData(data);
        }, []);*/

    useEffect(() => {
        fetchAgentMemberEnrollList();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, download]);

    const fetchAgentMemberEnrollList = async () => {
        try {
            setIsLoading(true);
            let response = await fetch_all_members_reenroll_past(searchQuery, page, perPage, sortColumn, sortDirection, download);
            if (response.data.code == 200) {
                if (download == "N") {
                    setData(response.data.data.logs);
                    setTotalRows(response.data.data.totalRows);
                } else {
                    handleExport(response.data.data.logs);
                    setDownload('N');
                }
            }

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };


    const columns = [
        {
            name: 'Request Date',
            selector: row => row.reEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Requested By',
            selector: row => row.reEnrollRequestBy,
            sortable: false,
        },
        {
            name: 'Request Notes',
            selector: row => row.reEnrollRequestNote,
            sortable: false,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            sortable: false,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Marked Status',
            selector: row => row.previousEnrollmentStatus,
            sortable: false,
        },
        {
            name: 'Marked Date',
            selector: row => row.previousAgentActionTime,
            sortable: false,
        },
        {
            name: 'Marked By',
            selector: row => row.previousAgentName,
            sortable: false,
        },
        {
            name: 'Review Date',
            selector: row => row.approvedDate,
            sortable: false,
        },
        {
            name: 'Reviewer',
            selector: row => row.approvedBy,
            sortable: false,
        },
        {
            name: 'Review Notes',
            selector: row => row.aprrovedNote,
            sortable: false
        },
        {
            name: 'Review Decision',
            selector: row => row.requestStatus,
            sortable: false,
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
    };



    const handleExport = () => {
        const modifiedData = data.map(item => ({
            'Request Date': item.reEnrollRequestedDate,
            'Requested By': item.reEnrollRequestBy,
            'Request Notes': item.reEnrollRequestNote,
            'Member ID': item.membershipNo,
            'Main Membership No': item.mainMembershipNo,
            'Member Name': item.memberName,
            'Marked Status': item.previousEnrollmentStatus,
            'Marked Date': item.previousAgentActionTime,
            'Marked By': item.previousAgentName,
            'Review Date': item.approvedDate,
            'Reviewer': item.approvedBy,
            'Reviewe Notes': item.aprrovedNote,
            'Review Decision': item.requestStatus,
        }));

        modifiedData.forEach(v => {
            delete v.approvedBy;
            delete v.approvedDate;
            delete v.logId;
            delete v.mobile;
            delete v.requestStatus;
            delete v.userId;
        });

        exportTableData("past-reenroll-request-data", modifiedData);
    };

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-6 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Member Name or Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>

                    <div className='col-md-6 d-flex justify-content-end'>
                        <div className='col-md-auto px-1'>
                            <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                        </div>
                        <div className='col-md-auto px-1'>
                            <button onClick={() => setDownload('Y')} className="btn btn-primary clear-search-btn">
                                <i className="bi bi-download"></i> Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*<DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />*/}
            {
                isLoading ? <LoaderLoader isLoading={isLoading} /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchAgentMemberEnrollList}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }



            <div className="modal fade" id="notesDisplay" tabindex="-1" aria-labelledby="notesDisplay" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={{ wordBreak: 'break-all' }}>
                            <p style={{ textAlign: 'justify', padding: '20px 20px', letterSpacing: '1.2px' }}>
                                {curentRequestNote}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PastRequestReenroll;
