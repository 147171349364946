import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { Link } from 'react-router-dom';
import './HealthLocker.css';
import { LoaderLoader } from '../utils/CommonLibrary';
import { fetchHealthlockerFiles, fetchReportTypes, removeHealthLockerFiles, addHealthLockerFile, updateHealthlockerFile, fetchHealthLockerFileInfo, fetchHealthlockerFileUrl } from '../../services/medengage/Service';
import ViewAssessmentV1 from '../cn/components/healthLocker/ViewAssessmentV1';
import ViewAssessmentV2 from '../cn/components/healthLocker/ViewAssessmentV2';


const HealthLockerMP = ({ showHealthLockerModal, handleCloseHealthLockerModal, memberBasicInfo }) => {

    const userId = memberBasicInfo['userId'];
    const [fileUrlId, setFileUrlId] = useState("");
    const [loading, setLoading] = useState(false);

    const [showAddNewRecordModal, setShowAddNewRecordModal] = useState(false);
    const handleShowAddNewRecordModal = () => {
        setShowAddNewRecordModal(true);
    }

    const [showEditRecordModal, setShowEditRecordModal] = useState(false);
    const handleShowEditRecordModal = () => {
        setShowEditRecordModal(true);
    }

    const [showViewHRAModal, setShowViewHRAModal] = useState(false);
    const [hraJson, setHraJson] = useState({});

    const [showAssessmentModal, setShowAssessmentModal] = useState(false);
    const [assessmentJson, setAssessmentJson] = useState({});

    const [selectedReportType, setSelectedReportType] = useState(1);
    const [reportTypes, setReportTypes] = useState([]);
    const [files, setFiles] = useState([]);

    const addFileObject = {
        reportTypeId: "",
        title: "",
        file: null,
        originalName: ""
    }
    const [addFileFormData, setAddFileFormData] = useState(addFileObject);
    const [addError, setAddError] = useState("");

    const handleAddFileFormChange = (e) => {
        setAddError("");
        const { name, value } = e.target;
        setAddFileFormData({
            ...addFileFormData,
            [name]: value
        });
    }

    const handleAddFileChange = (event) => {
        setAddError("");
        const file = event.target.files[0];
        const originalName = file.name;
        const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'tiff', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'dcm'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file && allowedExtensions.includes(fileExtension)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileData = e.target.result;
                setAddFileFormData({
                    ...addFileFormData,
                    file: fileData,
                    originalName: originalName
                });
            }
            reader.readAsDataURL(file);
        } else {
            setAddFileFormData({
                ...addFileFormData,
                file: null,
                originalName: ''
            });
            event.target.value = ''
            setAddError("Invalid file type. Please select a valid file.");
        }
    };

    const handleAddFileSubmit = async () => {
        setAddError("");
        let error = "";
        if (addFileFormData.reportTypeId === "") {
            error = "Please select the report type";
        } else if (addFileFormData.file === null) {
            error = "Please select the file";
        }

        if (error === "") {
            setLoading(true);
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }

            await addHealthLockerFile(userId, addFileFormData, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setAddFileFormData(addFileObject)
                        setShowAddNewRecordModal(false);
                        getHealthlockerFiles(selectedReportType);
                    } else {
                        setAddError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            setLoading(false);

            return () => request.cancel();
        } else {
            setAddError(error);
        }
    }

    /*Update Record*/
    const handleEditRecord = async (fileId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthLockerFileInfo(userId, fileId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    let info = {
                        fileId: response.data.data.info[0].fileId,
                        reportTypeId: response.data.data.info[0].reportTypeId,
                        title: response.data.data.info[0].description,
                        file: null,
                        originalName: ""
                    }
                    setEditFileFormData(info);
                    setShowEditRecordModal(true)
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const editFileObject = {
        fileId: "",
        reportTypeId: "",
        title: "",
        file: null,
        originalName: ""
    }
    const [editFileFormData, setEditFileFormData] = useState(editFileObject);
    const [editError, setEditError] = useState("");

    const handleEditFileFormChange = (e) => {
        setEditError("");
        const { name, value } = e.target;
        setEditFileFormData({
            ...editFileFormData,
            [name]: value
        });
    }

    const handleEditFileChange = (event) => {
        setEditError("");
        const file = event.target.files[0];
        const originalName = file.name;

        const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'tiff', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'dcm'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file && allowedExtensions.includes(fileExtension)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileData = e.target.result;
                setEditFileFormData({
                    ...editFileFormData,
                    file: fileData,
                    originalName: originalName
                });
            }
            reader.readAsDataURL(file);
        } else {
            setEditFileFormData({
                ...editFileFormData,
                file: null,
                originalName: ''
            });
            event.target.value = ''
            setEditError("Invalid file type. Please select a valid file.");
        }
    };

    const handleEditFileSubmit = async () => {
        setEditError("");
        let error = "";
        if (editFileFormData.reportTypeId === "") {
            error = "Please select the report type";
        }

        if (error === "") {
            setLoading(true);
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }

            await updateHealthlockerFile(userId, editFileFormData, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setEditFileFormData(editFileFormData)
                        setShowEditRecordModal(false);
                        getHealthlockerFiles(selectedReportType);
                    } else {
                        setEditError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            setLoading(false);
            return () => request.cancel();
        } else {
            setEditError(error);
        }
    }
    /*=============*/

    const handleTabClick = (reportTypeId) => {
        setFiles([]);
        setSelectedReportType(reportTypeId);
        getHealthlockerFiles(reportTypeId);
    }

    useEffect(() => {
        getReportTypes();
    }, [])

    const getReportTypes = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchReportTypes(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setReportTypes(response.data.data.reportTypes);
                    handleTabClick(response.data.data.reportTypes[0]['reportTypeId']);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getHealthlockerFileUrl = async (fileId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthlockerFileUrl(fileId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    window.open(response.data.data.fileUrl, '_blank', 'noopener,noreferrer');
                    setFileUrlId("");
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getHealthlockerFiles = async (reportTypeId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthlockerFiles(userId, reportTypeId, '', ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setFiles(response.data.data.files);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleDelete = (fileId, fileTitle) => {
        alertify.confirm(`Confirm Delete - ${fileTitle}`, `Are you sure you want to delete this file?`, () => {
            removeFile(fileId);
        }, () => {
        });
    }

    const removeFile = async (fileId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await removeHealthLockerFiles(fileId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getHealthlockerFiles(selectedReportType);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const sortReports = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedReports = [...files].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFiles(sortedReports);
    };

    const viewHRA = (json) => {
        setHraJson(json);
        setShowViewHRAModal(true)
    }

    const isEmptyRow = (row) => {
        return Object.values(row).every(value => value === '' || value === '0');
    };

    const viewAssessment = (json, version) => {
        let filteredJson = {};
        if (version === "1.0") {
            filteredJson = {
                ...json,
                medications: json.medications.filter(row => !isEmptyRow(row)),
                examinations: json.examinations.filter(row => !isEmptyRow(row)),
                labResults: json.labResults.filter(row => !isEmptyRow(row)),
                imagingResults: json.imagingResults.filter(row => !isEmptyRow(row)),
                version: version
            };
        } else {
            filteredJson = {
                ...json,
                medications: json.medications.filter(row => !isEmptyRow(row)),
                labResults: json.labResults.filter(row => !isEmptyRow(row)),
                imagingResults: json.imagingResults.filter(row => !isEmptyRow(row)),
                version: version
            };
        }


        //setAssessmentJson(json);
        setAssessmentJson(filteredJson);
        setShowAssessmentModal(true)
    }

    return (
        <>
            <LoaderLoader isLoading={loading} />
            <Modal id="HealthLockerDetails" show={showHealthLockerModal} onHide={handleCloseHealthLockerModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <div className='row align-items-center' style={{ width: "98%" }}>
                        <div className='col'>
                            <h1 className='modal-title'>Health Locker</h1>
                        </div>
                        <div className='col-auto'>
                            {
                                <button className="btn action-button blue-btn py-2 px-3" style={{ fontSize: "16px" }} onClick={handleShowAddNewRecordModal}>Add New Record</button>
                            }
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <Tabs defaultActiveKey={selectedReportType} id="uncontrolled-tab-example" onSelect={handleTabClick}>
                                {
                                    reportTypes.map((item, index) => {

                                        return <Tab eventKey={item.reportTypeId} title={item.reportType}>
                                            <div className='col-md-12'>
                                                <div className='card card-task-appoint'>
                                                    <div className='card-body'>
                                                        <div className='card-text'>
                                                            <div className='row align-items-center'>
                                                                <div>
                                                                    <div className="table-responsive mt-2" style={{ maxHeight: "53vh" }}>
                                                                        <table className="table table-striped table-bordered mb-0">
                                                                            <thead style={{ position: "sticky", top: "0" }}>
                                                                                <tr>
                                                                                    <th scope="col" onClick={() => sortReports('date')}>
                                                                                        Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>)}
                                                                                    </th>
                                                                                    <th scope="col">Description </th>
                                                                                    <th scope="col" onClick={() => sortReports('uploadedBy')}>
                                                                                        Uploaded By {sortConfig.key === 'uploadedBy' && (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>)}
                                                                                    </th>
                                                                                    <th scope="col">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    files.map((report, index) => {
                                                                                        return <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                                                            <td className='bg-td-green'>{report.date}</td>
                                                                                            <td className='bg-td-green'>{report.description}</td>
                                                                                            <td className='bg-td-green'>{report.uploadedBy}</td>
                                                                                            <td className='bg-td-green'>
                                                                                                <button onClick={() => {
                                                                                                    setFileUrlId(report.fileId);
                                                                                                    getHealthlockerFileUrl(report.fileId);
                                                                                                }} className={`btn btn-success health-locker-btn me-2 ${fileUrlId == report.fileId ? "hide" : ""}`}><i className="bi bi-eye"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    fileUrlId === report.fileId &&
                                                                                                    <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="" /></span>
                                                                                                }
                                                                                                {

                                                                                                    <button onClick={() => handleEditRecord(report.fileId)} className="btn btn-success health-locker-btn me-2"><i className="bi bi-pencil"></i></button>
                                                                                                }
                                                                                                {

                                                                                                    <button onClick={() => handleDelete(report.fileId, report.description)} className="btn btn-success health-locker-btn me-2"><i className="bi bi-trash"></i></button>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    })}

                                                                                {
                                                                                    files.length === 0 &&
                                                                                    <tr><td colSpan={4}>No files found</td></tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div >
                                                            </div >
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </Tab>
                                    })
                                }
                                <Tab eventKey="hra" title="HRA">
                                    <div className='col-md-12'>
                                        <div className='card card-task-appoint'>
                                            <div className='card-body'>
                                                <div className='card-text'>
                                                    <div className='row align-items-center'>
                                                        <div>
                                                            <div className="table-responsive mt-2" style={{ maxHeight: "53vh" }}>
                                                                <table className="table table-striped table-bordered mb-0">
                                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                                        <tr>
                                                                            <th scope="col" onClick={() => sortReports('date')}>
                                                                                Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>)}
                                                                            </th>
                                                                            <th scope="col">Description </th>
                                                                            <th scope="col" onClick={() => sortReports('uploadedBy')}>
                                                                                Uploaded By {sortConfig.key === 'uploadedBy' && (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>)}
                                                                            </th>
                                                                            <th scope="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            files.map((report, index) => {
                                                                                return <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                                                    <td className='bg-td-green'>{report.date}</td>
                                                                                    <td className='bg-td-green'>{report.description}</td>
                                                                                    <td className='bg-td-green'>{report.uploadedBy}</td>
                                                                                    <td className='bg-td-green'>
                                                                                        <button onClick={() => viewHRA(report.json, report.type)} className="btn btn-success health-locker-btn me-2"><i className="bi bi-eye"></i></button>
                                                                                    </td>
                                                                                </tr>
                                                                            })}

                                                                        {
                                                                            files.length === 0 &&
                                                                            <tr><td colSpan={4}>No files found</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div >
                                                    </div >
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </Tab>
                                <Tab eventKey="assessment" title="Assessment">
                                    <div className='col-md-12'>
                                        <div className='card card-task-appoint'>
                                            <div className='card-body'>
                                                <div className='card-text'>
                                                    <div className='row align-items-center'>
                                                        <div>
                                                            <div className="table-responsive mt-2" style={{ maxHeight: "53vh" }}>
                                                                <table className="table table-striped table-bordered mb-0">
                                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                                        <tr>
                                                                            <th scope="col" onClick={() => sortReports('date')}>
                                                                                Date {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>)}
                                                                            </th>
                                                                            <th scope="col" onClick={() => sortReports('uploadedBy')}>
                                                                                Uploaded By {sortConfig.key === 'uploadedBy' && (sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>)}
                                                                            </th>
                                                                            <th scope="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            files.map((report, index) => {
                                                                                return <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                                                    <td className='bg-td-green'>{report.date}</td>
                                                                                    <td className='bg-td-green'>{report.uploadedBy}</td>
                                                                                    <td className='bg-td-green'>
                                                                                        <button onClick={() => viewAssessment(report.json, report.version)} className="btn btn-success health-locker-btn me-2"><i className="bi bi-eye"></i></button>
                                                                                    </td>
                                                                                </tr>
                                                                            })}

                                                                        {
                                                                            files.length === 0 &&
                                                                            <tr><td colSpan={4}>No files found</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div >
                                                    </div >
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
            <Modal id="AddNewRecordDetails" show={showAddNewRecordModal} onHide={() => setShowAddNewRecordModal(false)} centered size="md" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Add New Record</h1>

                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <label className="form-label">Report Type <span className='required'>*</span></label>
                            <select name="reportTypeId" className="form-select select-input-ui" value={addFileFormData.reportTypeId} onChange={handleAddFileFormChange}>
                                <option value="">Select</option>
                                {
                                    reportTypes.map((item, index) => {
                                        return <option value={item.reportTypeId}>{item.reportType}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className="form-label">Description</label>
                            <textarea type="text" name="title" placeholder='Description' rows="3" className='form-control control-input-ui' value={addFileFormData.title} onChange={handleAddFileFormChange}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className="form-label">Select File <span className='required'>*</span></label>
                            <input type="file" accept=".pdf,.jpeg,.jpg,.png,.tiff,.doc,.docx,.xls,.xlsx,.csv,.txt,.dcm" className='form-control control-input-ui' onChange={handleAddFileChange} />
                        </div>
                        <div className='col-md-12 text-center'>
                            <div className={`${addError != "" ? "errorDiv" : "hide"}`}>{addError}</div>
                            <button class="btn btn-primary save-btn" onClick={() => handleAddFileSubmit()}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal id="EditRecordDetails" show={showEditRecordModal} onHide={() => setShowEditRecordModal(false)} centered size="md" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Update Record</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <label className="form-label">Report Type</label>
                            <select name="reportTypeId" className="form-select select-input-ui" value={editFileFormData.reportTypeId} onChange={handleEditFileFormChange}>
                                <option value="">Select</option>
                                {
                                    reportTypes.map((item, index) => {
                                        return <option value={item.reportTypeId}>{item.reportType}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className="form-label">Description</label>
                            <textarea type="text" name="title" placeholder='Description' rows="3" className='form-control control-input-ui' value={editFileFormData.title} onChange={handleEditFileFormChange}></textarea>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className="form-label">Select File</label>
                            <input type="file" accept=".pdf,.jpeg,.jpg,.png,.tiff,.doc,.docx,.xls,.xlsx,.csv,.txt,.dcm" className='form-control control-input-ui' onChange={handleEditFileChange} />
                        </div>
                        <div className='col-md-12 text-center'>
                            <div className={`${editError != "" ? "errorDiv" : "hide"}`}>{editError}</div>
                            <button class="btn btn-primary save-btn" onClick={() => handleEditFileSubmit()}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal id="viewHRA" show={showViewHRAModal} onHide={() => {
                setShowViewHRAModal(false);
                setHraJson({})
            }} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>HRA</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        Object.entries(hraJson).map(([key, field]) => {
                            const { value, question, category, score } = field;
                            return (
                                <div key={key} className='mt-3'>
                                    {
                                        (key == "bmi") ?
                                            <>
                                                <b><label>BMI:</label></b>
                                                <span>{value}</span>
                                            </>
                                            : ""
                                    }
                                    {key == "answer_1" && question == undefined && <><b><label>Input weight in kgs</label></b></>}
                                    {key == "answer_2" && question == undefined && <><b><label>Input height in cms</label></b></>}
                                    {question != "" && <><b><label>{question}</label></b></>}
                                    {key.includes("_specified") != "" && <><b><label>Specified:</label></b></>}
                                    {

                                        (Array.isArray(value)) ?
                                            <span><br />{value.join(', ')}<br /></span>
                                            : <><span><br />{(key != "bmi") ? value : ""}</span><br /></>
                                    }
                                </div>
                            );
                        })
                    }
                </Modal.Body >
            </Modal >

            <Modal id="viewAssessment" show={showAssessmentModal} onHide={() => {
                setShowAssessmentModal(false);
                setAssessmentJson({});
            }} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Assessment</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        assessmentJson && assessmentJson.version == "1.0" &&
                        <ViewAssessmentV1 assessmentJson={assessmentJson} />
                    }
                    {
                        assessmentJson && assessmentJson.version == "1.1" &&
                        <ViewAssessmentV2 assessmentJson={assessmentJson} memberBasicInfo={memberBasicInfo} />
                    }

                </Modal.Body>
            </Modal>
        </>
    )
}

export default HealthLockerMP