import React,{useEffect, useState} from 'react';
import '../LoginPage.css';
import { Link,useHistory, useNavigate  } from "react-router-dom";
import LanguageSetting from '../componenents/LanguageSetting';
import axios from "axios";
import { decruptCipherString, decruptCipherJson, CustomLoader, decryptData } from './CommonLibrary';
import moment from "moment";
import { encryptData } from './Utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccessLevel } from '../../services/cn/Service';

function ValidatePage() {
    
    useEffect(() => { 
        let dashboardLocalData = localStorage.getItem('dashboard'); 
        if(dashboardLocalData){ 
            localStorage.removeItem('dashboard'); 
        }

        let activeNavItemStore = localStorage.getItem('activeNavItem'); 
        if(activeNavItemStore){ 
            localStorage.removeItem('activeNavItem'); 
        }
    }, []); 
    
    const navigate = useNavigate();

    useEffect(() => {
        let preLogin = localStorage.getItem('preLogin') ? localStorage.getItem('preLogin') : '';
        if (preLogin) {
            const isLoggedIn = decryptData(preLogin);
            if (isLoggedIn != 'Yes') {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const [dashboardList, setDashboardList] = useState('');
    const [tokenValue, setTokenValue] = useState('');
    const [notAccess, setNotAccess] = useState(false);
    const [userName, setUserName] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accessLevel, setAccessLevel] = useState(true);
    const [hideUserName, setHideUserName] = useState(true);

    var setToken;
    const queryParams = new URLSearchParams(window.location.search);

    setToken = queryParams.get("token");

    useEffect(() => {
        // localStorage.clear();
        const fetchData = async () => {
            axios.post(process.env.REACT_APP_BASE_URL + 'sso/validate_user', {
                token: setToken,
              })
              .then(function (response) {
                
                if(response.data.code == 200){
                    let loginData = decruptCipherJson(response.data.data);

                    if(loginData.data.status == 1){
                        const redirectUrl = decruptCipherString(loginData.data.redirectUrl);
                        if(redirectUrl){
                            setRedirectTo(redirectUrl);
                        }
                        localStorage.setItem('uid', loginData.data.userAdminId);
                        localStorage.setItem('user', loginData.data.username);
                        localStorage.setItem('authToken', loginData.data.verificationToken);
                        localStorage.setItem('isLoggedIn', loginData.data.redirectUrl);
                        localStorage.setItem('tabs', loginData.data.tabs);
                        localStorage.setItem('pagesList', loginData.data.pagesList);
                        localStorage.setItem('dashboard', loginData.data.dashboard);
                        localStorage.setItem("cnId", loginData.data.cnId);
                        localStorage.setItem("inboxAccess", decruptCipherString(loginData.data.inboxAccess));
                        if(loginData.data.webExAccessToken){ 
                            localStorage.setItem("call_client_id", loginData.data.webExAccessToken); 
                        }
                        
                        const selectedDates = decruptCipherJson(loginData.data.selectedDates);
                        localStorage.setItem('selectedDates', encryptData(JSON.stringify([moment(selectedDates.startDate).toDate(), moment(selectedDates.endDate).toDate(),moment(selectedDates.ytdStartDate).toDate(), moment(selectedDates.ytdEndDate).toDate()])));
                        
                    }else if(loginData.data.status == 2){
                        localStorage.setItem('switch', response.data.switch);
                        localStorage.setItem('user', loginData.data.username);
                        localStorage.setItem("cnId", loginData.data.cnId); 
                        localStorage.setItem("inboxAccess", decruptCipherString(loginData.data.inboxAccess));
                        if(loginData.data.webExAccessToken){ 
                            localStorage.setItem("call_client_id", loginData.data.webExAccessToken);                         
                        }                        
                        setUserName(decruptCipherString(loginData.data.username));
                        setTokenValue(loginData.data.token);
                        setDashboardList(loginData.data.dashboardArray);
                        setLoading(false);
                    }else if(loginData.data.status == 304){
                        setUserName(decruptCipherString(loginData.data.username));
                        setNotAccess(true);
                        setLoading(false);
                    }else{
                        setHideUserName(false);
                        setNotAccess(true);
                        setLoading(false);
                    }
                    
                }else{
                    alert('something went wrong');
                }
               
              })
              .catch(function (error) {
                console.log(error);
              });
        }; 
        
        // const getAccessLevel = async () => {
        //     // const request = axios.CancelToken.source();
        //     // let ct = {
        //     //     cancelToken: request.token
        //     // }
        //     // console.log(ct)
        //     await fetchAccessLevel()
        //         .then((response) => {
        //             // console.log("access", response);
        //             if (response.data.code === 200) {
        //                 // console.log("Access Level",response.data.data.cnRoleAccess);
        //                 localStorage.setItem('InboxAccess', response.data.data.cnRoleAccess);
                        
        //             }
        //         }).catch(function (error) {
        //             console.log(error);
        //         });
        //     // return () => request.cancel();
        // }
        
        fetchData();
        // getAccessLevel();
    }, []);


    const loginUser = async (dashboardValue) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_BASE_URL + 'sso/login_dashboard', {
            dashboard: dashboardValue,
            token: tokenValue,
            accesslevel: accessLevel
          })
          .then(function (response) {
            
            if(response.data.code == 200){
                let loginData = decruptCipherJson(response.data.data);   
                
                const redirectUrl = decruptCipherString(loginData.data.redirectUrl);
                if(redirectUrl){
                    setRedirectTo(redirectUrl);
                }

                const access = decruptCipherString(loginData.data.inboxAccess)
                console.log(access)
                
                localStorage.setItem('uid', loginData.data.userAdminId);
                localStorage.setItem('user', loginData.data.username);
                localStorage.setItem('authToken', loginData.data.verificationToken);
                localStorage.setItem('isLoggedIn', loginData.data.redirectUrl);
                localStorage.setItem('tabs', loginData.data.tabs);
                localStorage.setItem('pagesList', loginData.data.pagesList);
                localStorage.setItem('dashboard', loginData.data.dashboard);
                localStorage.setItem('accesslevel', loginData.data.accesslevel);
                localStorage.setItem("cnId", loginData.data.cnId); 
                // console.log(loginData.data.inboxAccess);
                
                // localStorage.setItem("inboxAccess", loginData.data.inboxAccess); 
                setAccessLevel(loginData.data.accesslevel);
                const selectedDates = decruptCipherJson(loginData.data.selectedDates);
                localStorage.setItem('selectedDates', encryptData(JSON.stringify([moment(selectedDates.startDate).toDate(), moment(selectedDates.endDate).toDate(),moment(selectedDates.ytdStartDate).toDate(), moment(selectedDates.ytdEndDate).toDate()])));
                
            }else{  
                setNotAccess(true);
            }   
           
          })
          .catch(function (error) {
            console.log(error);
          });
    };


    if (redirectTo) {
       window.location.href = redirectTo;
    }

    if (loading) {
        return <div><CustomLoader /></div>;
    }


  return (
    <>
        <section className='LoginPage'>
            {/* <div className='language-setting'>
                <LanguageSetting/>
            </div> */}
            <div className="card-login">
                <div className="card-body">
                    <div className="container">
                        <div className="row gap-4">
                            <div className="col-md-auto ps-0">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/glwh3/hjthzlv2se.png" className="img-fluid logo-ksa" alt="" />
                            </div>
                            <div className="col-md">
                                <h1 className="login-title mb-4 "><span>GOQii</span> HealthEngage</h1>
                            </div>
                            {hideUserName && 
                           <div className='col-auto'>
                                <div className="dropdown-center">
                                    <button className="btn btn-secondary dropdown-toggle profile-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/dhdjh/898otsm9c0.png" className="profile-images" alt="Profile" />{userName}
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-light"   > 
                                        <li><a className="dropdown-item" href='/logout'><i className='bx bx-power-off' style={{color:"#FF0000"}}></i> Log out</a></li>
                                    </ul>
                                </div>
                            </div> }
                        </div>
                        
                        <div className="row">
                           
                            <div className="col-md-12 px-0 mb-2">
                                <div className="form-group">

                                {
                                    notAccess ? 
                                    <>
                                        <div className="row mb-5">
                                        
                                            <div className="col-md-12 text-center">
                                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/yurhg/4140jj6978.png" alt="" style={{height:"240px"}} />
                                                <h1 className="login-subtitle my-3" style={{fontWeight:"400"}}>It seems like you're on a secret mission without the right access.
                                            <br/> Better call your system administrator to unlock the doors!</h1>
                                            <Link to= "/login" className="btn btn-primary btn-login">
                                                Ok
                                            </Link>
                                            </div>  
                                        </div>
                                        
                                    </> : 
                                    <>
                                    {dashboardList ? (
                                        <>
                                            <div className='row mb-4 justify-content-center'>
                                            <div className="col-md-12">
                                            <h1 className="login-subtitle">Select your App</h1>
                                        </div>
                                            {dashboardList.map((data, index) => (
                                                <div className='col-md-auto px-4 text-center mb-4' key={index}>
                                                <button className="btn btn-light btn-login-box my-1" onClick={(e) => loginUser(data.value)}>
                                                    <img src={data.img} className="img-fluid box-ksa-icon m-auto" alt="" />
                                                </button>
                                                <h2 className='mt-1 login-box-title'>{data.text}</h2>
                                                </div>
                                            ))}
                                            </div>
                                        </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                }

                                
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className='powered-by mt-2'>
             <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/hstog/j1xo7j89r8.png" style={{height:"42px"}} className="" alt="" />
            </div>
        </section>
    </>
  );
}

export default ValidatePage;
