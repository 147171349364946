import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { fetch_download_agent, fetch_reports_agent, getFileLink } from '../../../services/met/Service';
import { LoaderLoader, formatDate } from '../../utils/CommonLibrary';
import { decryptData } from '../../utils/Utils';
import CalendarDatePicker from '../../componenents/CalendarDatePicker';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import 'react-date-range/dist/theme/default.css';


function ContentAgentReportsTable() {

    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    let ytdStart, ytdEnd; 

    const storedDates = JSON.parse(decryptData(localStorage.getItem('selectedDates')));
    ytdStart = storedDates[2]
    ytdEnd = storedDates[3];
    

    const [label, setLabel]  = useState(`${moment(new Date()).format('D MMM, YYYY')} - ${moment(new Date()).format('D MMM, YYYY')}`); 

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [selectedReport, setSelectedReport] = useState('All');
    const [dateRange, setDateRange] = useState([null, null]);
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    

    
    const handleDateChange = (start, end) => {
        setLabel(`${start.format('D MMM, YYYY')} - ${end.format('D MMM, YYYY')}`);
        setStartDate(start);
        setEndDate(end);
    };
    
    
    useEffect(() => {
        fetchCareCoordinatorData();
    }, []);

    const downloadLink = async (reportId)=>{
        try{
            setIsLoading(true);
             let resp = await getFileLink(reportId);
             let downloadUrl = resp.data.data.url;
             if (downloadUrl) {
                const link = document.createElement('a');
                const filename = `report_${reportId}.xls`;
                link.href = downloadUrl;
                link.setAttribute('download', filename); // Specify the filename here if necessary
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
              } else {
                console.error('Download URL not found in response');
              }
        }catch (error) {
            console.error(error);
        } 
        finally {
              setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    const columns = [
        {
            name: 'Generated By',
            selector: row => row.generatedBy,
            sortable: true,
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },
        
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Generated On',
            selector: row => row.createdDate,
            sortable: true,
        },
        {
            name: 'Download Reports',
            // selector: row => row.action,
            cell: row => (
                <div>
                    {row.reportStatus === 'completed' ? <span onClick={()=>downloadLink(row.reportId)} style={{cursor:'pointer'}}> <i className="bi bi-download"></i></span>
                    : 'Pending'}
                </div>
            ),
        },
    ];


    const handleClear = () => {
        setSearchQuery('');
        setSelectedStatus('All');
        setSelectedReport('All');
        setDateRange([null, null]);
    };

    const fetchCareCoordinatorData = async() => {
        try{
            setIsLoading(true);
                let response = await fetch_reports_agent();
                    if(response.data.code === 200){
                        setReportData(response.data.data);
                    }
                }catch (error) {
                    console.error(error);
                  } 
                  finally {
                      setIsLoading(false);  // Will be called regardless of success or failure
                  }
            }
    const data = reportData;

      
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };
    const filterData = data.filter(user => {
        
        const matchesSearchQuery = 
        (typeof user.generatedBy === 'string' && user.generatedBy.toLowerCase().includes(searchQuery.toLowerCase()));
        
        return matchesSearchQuery;
    });
    
    const genrateReport = async () =>{
        setErrorMsg('');
        if(selectedReport == 'All'){
            setErrorMsg('Select Report type');
            return false;
        }
        try {
            setIsLoading(true);
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            let response = await fetch_download_agent(sdate,edate,selectedReport);
            fetchCareCoordinatorData();
            
        } catch (error) {
          console.error(error);
        } 
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }
    

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-2'>
                    <div className='col-md-4 ps-2 pe-1' style={{backgroundColor:" #f0f4ff", border:"0", height:"100%",borderRadius:"14px 0 0 14px"}}>
                    <div className='card-body py-2'>
                        <select
                            value={selectedReport}
                            onChange={e => setSelectedReport(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Report:--</option>
                            <option value="callhistory">Call History Report</option>
                        </select>
                        {errorMsg && <div className="text-danger">{errorMsg}</div>}
                    </div>
                    </div>
                    <div className='col-md-3 px-1' style={{backgroundColor:" #f0f4ff", border:"0", height:"100%",borderRadius:"0px"}}>
                    <div className='card-body py-2'>
                        {/* <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange}  /> */}

                        <DateRangePicker
                                initialSettings={{
                                  startDate: startDate,
                                  endDate: endDate,
                                  opens:  true ? 'left' : 'right',
                                  locale: {
                                    format: 'D MMM, YYYY',  // Change the date format here
                                  },
                                  ranges: {
                                    Today: [moment().toDate(), moment().toDate()],
                                    Yesterday: [
                                      moment().subtract(1, 'days').toDate(),
                                      moment().subtract(1, 'days').toDate(),
                                    ],
                                    'Last 7 Days': [
                                      moment().subtract(6, 'days').toDate(),
                                      moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                      moment().subtract(29, 'days').toDate(),
                                      moment().toDate(),
                                    ],
                                    'This Month': [
                                      moment().startOf('month').toDate(),
                                      moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                      moment().subtract(1, 'month').startOf('month').toDate(),
                                      moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                    'PSTD': [
                                      moment(ytdStart).toDate(),
                                      moment(ytdEnd).toDate(),
                                    ],
                                  },
                                }}
                                onCallback={handleDateChange}
                              >
                                        <div
                                                id="reportrange"
                                                style={{
                                                    background: '#fff',
                                                    cursor: 'pointer',
                                                    padding: '10px 14px',
                                                    border: '1px solid #DCDCDC',
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    gap:'8px'
                                                }}
                                                >
                                                <i className="bi bi-calendar4-week"></i>&nbsp;
                                                <span>{label}</span> <i className="fa fa-caret-down"></i>
                                                </div>

                              </DateRangePicker>
                    </div>
                    </div>
                    <div className='col-md-auto ps-1 pe-2' style={{backgroundColor:" #f0f4ff", border:"0", height:"100%",borderRadius:"0 14px 14px 0"}}>
                    <div className='card-body py-2'>
                        <button type='button' className='btn btn-primary add-new-program-btn' onClick={genrateReport}>Generate
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <LoaderLoader isLoading={isLoading} />
            <DataTable
                columns={columns}
                data={filterData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default ContentAgentReportsTable;
