import React, { useState, useEffect, useRef } from "react";
import { FiX } from "react-icons/fi";
import {
  whatsapp_list,
  sendTemplateApi,
} from "../../../../services/whatsapp/Service";
import { useInboxContext } from "./CnInboxContext";

const TemplateMockup = ({ template }) => {
  // Function to replace variables in content
  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;

    const commonTLDs = [
      "com",
      "org",
      "net",
      "edu",
      "gov",
      "mil",
      "io",
      "co",
      "ai",
      "app",
      "dev",
      "uk",
      "us",
      "eu",
      "in",
      "de",
      "fr",
    ];

    // First handle line breaks
    content = content.replace(/\\n/g, "<br />");

    // Split content by HTML tags and process each part
    const parts = content.split(/(<[^>]*>)/);
    content = parts
      .map((part) => {
        // If it's an HTML tag, return it unchanged
        if (part.startsWith("<") && part.endsWith(">")) {
          return part;
        }
        // If part contains Arabic/Urdu, wrap it in RTL div
        if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
          return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
        }
        // If it's non-empty text without Arabic/Urdu, wrap it in regular div
        if (part.trim()) {
          return `<div>${part}</div>`;
        }
        // Return empty parts unchanged
        return part;
      })
      .join("");

    console.log(content, "content");

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(
            (tld) =>
              url.toLowerCase().includes(`.${tld}`) &&
              url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = "https://" + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  return (
    <div className="tw-w-full md:tw-w-[360px] tw-mx-auto">
      <div className="tw-relative tw-h-[600px]">
        <div className="tw-w-full tw-h-full tw-bg-[url('https://appcdn.goqii.com/storeimg/49871_1729598479.png')] tw-bg-contain tw-bg-no-repeat tw-bg-center"></div>

        <div className="tw-absolute tw-top-[85px] tw-left-[25px] tw-right-[25px] tw-bottom-[85px] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
          <div className="tw-text-xs tw-text-gray-700 tw-bg-white tw-rounded-lg tw-px-2 tw-w-1/3 tw-text-center tw-mx-auto tw-mb-1 tw-font-semibold">
            Today
          </div>

          <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-shadow-sm">
            <div className="tw-text-gray-600 tw-text-sm tw-mb-3">
              {template?.mediaUrl && (
                <div className="tw-mb-2">
                  {template.type === "Image" ? (
                    <img
                      src={template.mediaUrl}
                      alt={template.templateName}
                      className="tw-w-full tw-h-full tw-object-cover tw-rounded"
                    />
                  ) : template.type === "Video" ? (
                    <video className="tw-w-full tw-h-full tw-rounded" controls>
                      <source src={template.mediaUrl} type="video/mp4" />
                    </video>
                  ) : template.type === "Document" ? (
                    <div className="tw-bg-gray-100 tw-p-3 tw-rounded tw-flex tw-items-center tw-gap-2">
                      <svg
                        className="tw-w-6 tw-h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="tw-text-sm">Document attached</span>
                    </div>
                  ) : null}
                </div>
              )}

              {template.headerContent && (
                <div className="tw-font-medium tw-mb-2">
                  {template.headerContent}
                </div>
              )}

              <p
                className="tw-whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: replaceVariables(template.templateBody),
                }}
              ></p>
            </div>

            {template.footerContent && (
              <p className="tw-text-sm tw-text-gray-500 tw-mb-2">
                {template.footerContent}
              </p>
            )}

            {template.buttons && template.buttons.length > 0 && (
              <div className="tw-flex tw-flex-col tw-gap-px tw-mt-3 tw-mx-[-12px] tw-mb-[-12px]">
                {template.buttons.map((button, index) => (
                  <span
                    key={index}
                    className={`
          tw-text-[#1c4cba] dark:tw-text-[#4d82ff]
          tw-text-sm
          tw-font-medium
          tw-py-3
          tw-px-4
          tw-text-center
          tw-bg-white
          hover:tw-bg-gray-50
          active:tw-bg-gray-100
          tw-transition-colors
          tw-border-t
          tw-border-gray-200
          first:tw-border-t-0
          ${index === 0 ? "tw-rounded-b-lg" : ""}
          tw-cursor-pointer
          tw-flex
          tw-items-center
          tw-justify-center
          tw-gap-2
          tw-w-full
        `}
                  >
                    {button.type === "QUICK_REPLY" ? (
                      <span className="tw-truncate">{button.title}</span>
                    ) : null}
                  </span>
                ))}
              </div>
            )}

            {template.websiteUrl && template.websiteUrl.length > 0 && (
              <div className="tw-flex tw-flex-col tw-gap-px tw-mt-3 tw-mx-[-12px] tw-mb-[-12px]">
                {template.websiteUrl.map((link, index) => (
                  <span
                    key={index}
                    className={`
          tw-text-[#1c4cba] dark:tw-text-[#4d82ff]
          tw-text-sm
          tw-font-medium
          tw-py-3
          tw-px-4
          tw-text-center
          tw-bg-white
          hover:tw-bg-gray-50
          active:tw-bg-gray-100
          tw-transition-colors
          tw-border-t
          tw-border-gray-200
          first:tw-border-t-0
          ${index === 0 ? "tw-rounded-b-lg" : ""}
          tw-cursor-pointer
          tw-flex
          tw-items-center
          tw-justify-center
          tw-gap-2
          tw-w-full
        `}
                  >
                    {link.type === "URL" && (
                      <>
                        <svg
                          className="tw-w-4 tw-h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                        <span className="tw-truncate">{link.title}</span>
                      </>
                    )}
                    {link.type === "PHONE_NUMBER" && (
                      <>
                        <svg
                          className="tw-w-4 tw-h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                          />
                        </svg>
                        <span className="tw-truncate">{link.title}</span>
                      </>
                    )}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CnTemplatePopup = ({ onClose, id, getchat, scrollToBottom }) => {
  const { setLoadingMore, setChatpage, setChatResult } = useInboxContext();

  const [searchTemplate, setSearchTemplate] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [allDataFetched, setAllDataFetched] = useState(false); // New state to track if all data is fetched
  const templateListRef = useRef(null);
  const [messageSent, setMessageSent] = useState(false); // State to track message sent status
  const [messageSentText, setMssageSentText] = useState(""); // State to track message sent status

  const [isSending, setIsSending] = useState(false);
  const searchTimeoutRef = useRef(null);

  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;

    const commonTLDs = [
      "com",
      "org",
      "net",
      "edu",
      "gov",
      "mil",
      "io",
      "co",
      "ai",
      "app",
      "dev",
      "uk",
      "us",
      "eu",
      "in",
      "de",
      "fr",
    ];

    // First handle line breaks
    content = content.replace(/\\n/g, "<br />");

    // Split content by HTML tags and process each part
    const parts = content.split(/(<[^>]*>)/);
    content = parts
      .map((part) => {
        // If it's an HTML tag, return it unchanged
        if (part.startsWith("<") && part.endsWith(">")) {
          return part;
        }
        // If part contains Arabic/Urdu, wrap it in RTL div
        if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
          return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
        }
        // If it's non-empty text without Arabic/Urdu, wrap it in regular div
        if (part.trim()) {
          return `<div>${part}</div>`;
        }
        // Return empty parts unchanged
        return part;
      })
      .join("");

    console.log(content, "content");

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(
            (tld) =>
              url.toLowerCase().includes(`.${tld}`) &&
              url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = "https://" + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  // const fetchTemplates = async (searchText = '', pageNumber = 1) => {
  //     if (loading || allDataFetched) return; // Prevent fetching if loading or all data is already fetched

  //     setLoading(true);
  //     setIsFetching(true);
  //     try {
  //         const response = await whatsapp_list({ userId: id, search: searchText, page: pageNumber });
  //         if (response.data && response.data.data.templateData.length > 0) {
  //             const newTemplates = response.data.data.templateData;

  //             setDefaultTemplates((prevTemplates) => [...prevTemplates, ...newTemplates]);
  //             setTemplates((prevTemplates) => [...prevTemplates, ...newTemplates]);
  //             setIsFetching(false);
  //             setLoading(false);
  //         } else {
  //             setAllDataFetched(true); // Set to true if no new data is returned
  //             setIsFetching(false);
  //             setLoading(false);
  //         }
  //     } catch (error) {
  //         console.error('Failed to fetch templates:', error);
  //         setLoading(false);
  //         setIsFetching(false);
  //     }
  // };

  const fetchTemplates = async (
    searchText = "",
    pageNumber = 1,
    isNewSearch = false
  ) => {
    if (loading || (allDataFetched && !isNewSearch)) return;

    setLoading(true);
    setIsFetching(true);

    try {
      const response = await whatsapp_list({
        userId: id,
        search: searchText,
        page: pageNumber,
      });

      if (response.data && response.data.data.templateData.length > 0) {
        const newTemplates = response.data.data.templateData;

        if (isNewSearch) {
          // For new searches, replace existing templates
          setTemplates(newTemplates);
        } else {
          // For pagination, append to existing templates
          setTemplates((prev) => [...prev, ...newTemplates]);
        }

        setAllDataFetched(newTemplates.length < 50); // Assuming 50 is the page size
      } else {
        setAllDataFetched(true);
        if (isNewSearch) {
          setTemplates([]); // Clear templates if it's a new search with no results
        }
      }
    } catch (error) {
      console.error("Failed to fetch templates:", error);
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTemplate(searchValue);

    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Set new timeout for search
    searchTimeoutRef.current = setTimeout(() => {
      setPage(1);
      setAllDataFetched(false);
      fetchTemplates(searchValue, 1, true);
    }, 500); // 500ms debounce
  };

  // console.log("templates", templates);

  // useEffect(() => {
  //     fetchTemplates(searchTemplate, page);
  // }, [searchTemplate]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  });

  // Initial load
  useEffect(() => {
    fetchTemplates("", 1, true);
  });

  // const handleScroll = () => {
  //     if (templateListRef.current) {
  //         const { scrollTop, scrollHeight, clientHeight } = templateListRef.current;
  //         if (scrollTop + clientHeight >= scrollHeight - 5 && !loading && !isFetching && !allDataFetched) {
  //             setPage((prevPage) => prevPage + 1);
  //         }
  //     }
  // };

  const handleScroll = () => {
    if (templateListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = templateListRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 20;

      if (isNearBottom && !loading && !isFetching && !allDataFetched) {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchTemplates(searchTemplate, nextPage, false);
      }
    }
  };

  useEffect(() => {
    const listElement = templateListRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
      return () => listElement.removeEventListener("scroll", handleScroll);
    }
  }, [loading, isFetching, allDataFetched, page, searchTemplate]);

  // useEffect(() => {
  //     if (searchTemplate) {
  //         setPage(1);
  //         setTemplates([]);
  //         setAllDataFetched(false); // Reset when searching
  //         fetchTemplates(searchTemplate, 1);
  //     }
  // }, [searchTemplate]);

  // const handleSendTemplate = async () => {
  //     if (selectedTemplate) {
  //         try {
  //             setIsSending(true);
  //             const response = await sendTemplateApi({
  //                 userId: id,
  //                 templateId: selectedTemplate.templateId,
  //                 templateBody: selectedTemplate.templateBody,

  //             });
  //             console.log('Template sent:', response);

  //             setMessageSent(true);
  //             setTimeout(() => {
  //                 setMessageSent(false); // Hide the notification after 3 seconds
  //                 onClose();
  //             }, 3000);

  //             if(response.data.code==200){
  //                 //alert("dwdwd");
  //                 setChatpage(1)
  //                 setChatResult([])
  //                 getchat(id,1);
  //                 setLoadingMore(true);
  //             }
  //         } catch (error) {
  //             console.error('Failed to send template:', error);
  //         } finally {
  //             setIsSending(false); // Stop loading regardless of outcome
  //         }
  //     }
  // };

  // console.log(selectedTemplate?.templateBody)

  const handleSendTemplate = async () => {
    if (!selectedTemplate || isSending) return;

    try {
      setIsSending(true);
      setChatpage(1);
      const response = await sendTemplateApi({
        userId: id,
        templateId: selectedTemplate.templateId,
        templateBody: "", //replaceVariables(selectedTemplate.templateBody),
      });
      scrollToBottom();

      if (response.data.code === 200) {
        setMssageSentText("Message Sent Successfully!");
        setMessageSent(true);

        // setTimeout(() => {
        setChatResult([]);
        getchat(id, 1); // Fetch chat for the new message
        //}, 1000);

        setLoadingMore(true);
        setTimeout(() => {
          setMessageSent(false);
          onClose();
        }, 3000);
      } else {
        setMessageSent(true);
        setMssageSentText("Something Went wrong . Please retry later!");
        setTimeout(() => {
          setMessageSent(false);
          //onClose();
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to send template:", error);
    } finally {
      setIsSending(false);
    }
  };

  console.log("templates", templates);

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-[9999]">
      <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-w-3/4 tw-h-3/4 tw-flex tw-flex-col">
        <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-bg-white">
          <h2 className="tw-text-xl tw-font-bold tw-text-gray-600">
            Select Template
          </h2>
          <span
            className="tw-cursor-pointer tw-text-xl tw-font-bold tw-mb-1.5"
            onClick={onClose}
          >
            <FiX />
          </span>
        </div>
        <div className="tw-flex tw-flex-grow tw-overflow-hidden">
          <div className="tw-w-1/3 tw-border-r tw-p-4 tw-flex tw-flex-col">
            {/* <div className="tw-mb-4">
                             <input
                                type="text"
                                placeholder="Search templates..."
                                className="tw-w-full tw-border tw-rounded tw-p-2"
                                value={searchTemplate}
                                onChange={handleSearchChange}
                            />
                        </div> */}
            <div className="form-group tw-mb-4" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search Template"
                className="form-control input-field-ui ps-5"
                value={searchTemplate}
                onChange={handleSearchChange}
              />
              <img
                src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                className="input-icon-seacrh"
                alt=""
              />
            </div>
            <div ref={templateListRef} className="tw-overflow-y-auto tw-flex-1">
              {templates.length === 0 && !loading ? (
                <div className="tw-text-center tw-text-gray-500 tw-py-4">
                  No templates found
                </div>
              ) : (
                templates.map((template, index) => (
                  <div
                    key={`${template.templateId}-${index}`}
                    className={`tw-p-2 tw-cursor-pointer tw-border-b tw-transition-all tw-duration-300 hover:tw-bg-gray-100 
                                            ${
                                              selectedTemplate?.templateId ===
                                              template.templateId
                                                ? "tw-bg-[#f1f6fc]"
                                                : ""
                                            }`}
                    onClick={() => setSelectedTemplate(template)}
                  >
                    {template.templateName}
                  </div>
                ))
              )}
              {loading && (
                <div className="tw-text-center tw-py-4">
                  <div className="tw-animate-spin tw-inline-block tw-w-6 tw-h-6 tw-border-[3px] tw-border-current tw-border-t-transparent tw-text-gray-400 tw-rounded-full" />
                </div>
              )}
            </div>
          </div>
          <div className="vr"></div>
          <div className="tw-w-2/3 tw-p-4 tw-overflow-y-auto">
            {selectedTemplate ? (
              <div className="tw-flex tw-flex-row">
                <div className="tw-w-1/2">
                  <h5 className="tw-font-bold tw-mb-4">Message</h5>
                  <div className="tw-overflow-y-auto tw-pr-2">
                    <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4">
                      <div className="tw-mb-4">
                        <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">
                          Body:
                        </p>
                        <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                          {/* <p className="tw-text-sm tw-text-gray-600 tw-mb-1">{selectedTemplate.templateName}</p> */}
                          <div
                            className="tw-text-sm tw-break-words tw-whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{
                              __html: replaceVariables(
                                selectedTemplate.templateBody
                              ),
                            }}
                          />
                        </div>
                      </div>

                      <div className="tw-mb-4">
                        <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">
                          Buttons:
                        </p>
                        <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                          {selectedTemplate.buttons &&
                          selectedTemplate.buttons.length > 0 ? (
                            <div className="tw-space-y-2">
                              {selectedTemplate.buttons.map((button, index) => (
                                <p
                                  key={index}
                                  className="tw-text-sm tw-break-words"
                                >
                                  {button.type}: {button.title}
                                </p>
                              ))}
                            </div>
                          ) : (
                            <p className="tw-text-sm tw-break-words">
                              No buttons
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="tw-mb-4">
                        <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">
                          Website URLs & Phone Numbers:
                        </p>
                        <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                          {selectedTemplate.websiteUrl &&
                          selectedTemplate.websiteUrl.length > 0 ? (
                            <div className="tw-space-y-2">
                              {selectedTemplate.websiteUrl.map(
                                (link, index) => (
                                  <p
                                    key={index}
                                    className="tw-text-sm tw-break-words"
                                  >
                                    {link.type === "URL"
                                      ? `URL: ${link.title} (${link.url})`
                                      : link.type === "PHONE_NUMBER"
                                      ? `Phone: ${link.title} (${link.phone})`
                                      : ""}
                                  </p>
                                )
                              )}
                            </div>
                          ) : (
                            <p className="tw-text-sm tw-break-words">
                              No website URLs or phone numbers
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="tw-mb-4">
                        <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">
                          Header Content:
                        </p>
                        <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                          <p className="tw-text-sm tw-break-words">
                            {selectedTemplate.headerContent ||
                              "No header content"}
                          </p>
                        </div>
                      </div>

                      <div className="tw-mb-4">
                        <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">
                          Media URL:
                        </p>
                        <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                          <p className="tw-text-sm tw-break-all hover:tw-whitespace-normal">
                            {selectedTemplate.mediaUrl || "No media URL"}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">
                          Category:
                        </p>
                        <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                          <p className="tw-text-sm tw-break-words">
                            {selectedTemplate.category || "No category"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vr"></div>
                <div className="tw-pl-2 w-1/2">
                  <h5 className="tw-font-bold tw-mb-4">Preview:</h5>
                  <TemplateMockup template={selectedTemplate} />
                </div>
                            </div>
                        ) : (
                            <div className="tw-text-center tw-text-gray-500 tw-py-4">
                                Select a template to see preview
                            </div>
                        )}
                    </div>
                </div>
                <div className="tw-p-4 tw-border-t tw-flex tw-justify-center">
                  {!messageSent && (
                <span 
                className={`
                  tw-bg-[#03335b] 
                  tw-text-white 
                  tw-px-6 
                  tw-py-2 
                  tw-rounded 
                  tw-flex 
                  tw-items-center 
                  tw-justify-center 
                  tw-min-w-[180px] 
                  ${!selectedTemplate || isSending 
                    ? 'tw-opacity-50 tw-cursor-not-allowed tw-bg-gray-400 hover:tw-bg-gray-400' 
                    : 'hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-cursor-pointer'
                  }
                `}
                    onClick={handleSendTemplate}
                    disabled={isSending}
                >
                    {isSending ? (
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <svg className="tw-animate-spin tw-h-5 tw-w-5 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Sending...</span>
                        </div>
                    ) : (
                        'Send'
                    )}
                </span>
                  )}
            </div>
                {messageSent && ( // Conditionally render the message sent notification
                    <div className="tw-bg-green-500 tw-text-white tw-p-2 tw-text-center tw-rounded tw-mt-4">
                       <svg className="tw-h-5 tw-w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span>{messageSentText}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CnTemplatePopup;
