import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const WhatsAppAnalyticsBar2 = (props) => { 
  // Destructure props for readability
  const { dataMonth } = props;
  const { columnName: careNavigators, dataValue: membersManaged, color: colorCode, displayName : axisName,subvalue:valueSuffix } = dataMonth;

 const processedData = membersManaged.map((timeStr) => ({
    y: Number(timeStr) || 0, // Numerical value for rendering
    original: timeStr // Original string for display
  }));

  // yAxis configuration
  const yAxisJson = {
    title: {
      text: "   "
    }
  };

  const tooltipJson = {
    
    // convertTimeToSeconds: function () {
    //   // Display original string in tooltip
    //   return `<b>${this.key}</b>: ${this.point.original}`;
    // }
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.original}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  };

  // // Tooltip configuration
  // const tooltipJson = {
  //   shared: true,
  //   valueSuffix: ' '+valueSuffix
  // };

  // Series data
  const columnData = [{
    name: axisName,
    data: processedData,
    color: colorCode
  }];

  // Highcharts configuration
  const optionsCN1 = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
          enabled: true,
          alpha: 0,
          beta: 15,
          depth: 50,
          viewDistance: 0
      },
      marginTop: [34]
  },
    title: "    ",
    subtitle: null,
    xAxis: {
      categories: careNavigators,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    exporting: {
      enabled: true
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN1}
      />
    </div>
  );
};

export default WhatsAppAnalyticsBar2;
