import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import WhatsAppAnalyticsBar from "./WhatsAppAnalyticsBar";
import WhatsAppAnalyticsPie from "./WhatsAppAnalyticsPie";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { encryptData } from "../../utils/Utils";
import {
  fetch_count,
  fetch_carenavigator,
  fetch_admin_insights,
  fetch_admins,
  fetchDashboardList,
  fetch_agent_insights,
} from "../../../services/whatsapp/Service";
import WhatsAppAnalyticsBar2 from "./WhatsAppAnalyticsBar2";
const AnalyticsDashboard = () => {
  const [loading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [seriesGraph, setSeriesGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState([]);
  const [agentGraph, setAgentGraph] = useState([]);
  // const [campaignGraph, setCampaignGraph] = useState([]);
  
  

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [adminsUsers, setAdminusers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Optional if provided by API

  //const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [startDate, setStartDate] = useState(moment());

  const [endDate, setEndDate] = useState(moment());

  // const [graphScale, setGraphScale] = useState(false)

  const [selectedDashboard, setSelectedDashboard] = useState('all');

  const [dashboardList, setDashboardList] = useState([]);
  

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  
  

  // const handleSort = (key) => {
  //   let direction = "asc";
  //   if (sortConfig.key === key) {
  //     direction = sortConfig.direction === "asc" ? "desc" : "asc";
  //   }
  //   setSortConfig({ key, direction });
  // };

  // const getSortIcon = (columnName) => {
  //   if (sortConfig.key !== columnName) {
  //     return <i className="fa fa-sort tw-ml-2 tw-text-gray-400"></i>;
  //   }
  //   return sortConfig.direction === "asc" ? (
  //     <i className="fa fa-sort-up tw-ml-2"></i>
  //   ) : (
  //     <i className="fa fa-sort-down tw-ml-2"></i>
  //   );
  // };

  const getSortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Get sorted data
  // const sortedData = getSortedData(adminsUsers);

  // Add this handler function
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Mock the localStorage for the date range picker
  useEffect(() => {
    const mockStoredDates = [
      moment().subtract(7, "days"),
      moment(),
      moment().startOf("year"),
      moment(),
    ];
    localStorage.setItem(
      "selectedDates",
      encryptData(JSON.stringify(mockStoredDates))
    );
    fetchDashboard();
  }, []);

  const fetchDashboard = async()=>{
    try {
      const resp = await fetchDashboardList(); // Pass the current page to the API
      if (resp.data.code === 200) {
        setDashboardList(resp.data.data);       
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }

  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchData = async (start, end) => {
    try {
      const response = await fetch_count({ startDate: start, endDate: end, dashboardId :selectedDashboard }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data.insights.length > 0) {
        setAnalyticsData(response.data.data.insights);
      }
      // Assuming the API returns an array of data objects that you need for your dashboard
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_carenavigator_insights = async (start, end) => {
    try {
      const response = await fetch_carenavigator({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setSeriesGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admin_insights_data = async (start, end) => {
    try {
      const response = await fetch_admin_insights({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      console.log("admin response",response.data.data);
      if (response.data.code == 200 && response.data.data) {
        setAdminGraph(response.data.data);
        console.log("admin response",response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_agent_insights_data = async (start, end) => {
    try {
      const responseRow = await fetch_agent_insights({
        startDate: start,
        endDate: end,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (responseRow.data.code == 200 && responseRow.data.data) {
        setAgentGraph(responseRow.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // const fetch_campaign_insights_data = async (start, end) => {
  //   try {
  //     const campaignRow = await fetch_campaign_insights({
  //       startDate: start,
  //       endDate: end,
  //       dashboardId :selectedDashboard,
  //     }); // Pass the current page to the API
  //     if (campaignRow.data.code == 200 && campaignRow.data.data) {
  //       setCampaignGraph(campaignRow.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  const fetch_admins_data = async (start, end, page = 1, limit = 10) => {
    try {
      const response = await fetch_admins({
        startDate: start,
        endDate: end,
        page: page,
        limit: limit,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminusers(response.data.data.list);
        setTotalRecords(response.data.data.total); // Assuming response includes `total`
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(formatDate(startDate), formatDate(endDate));

    fetch_carenavigator_insights(formatDate(startDate), formatDate(endDate));

    fetch_admin_insights_data(formatDate(startDate), formatDate(endDate));

    fetch_agent_insights_data(formatDate(startDate), formatDate(endDate));

    // fetch_campaign_insights_data(formatDate(startDate), formatDate(endDate));
    
  }, [startDate, endDate, selectedDashboard]); // Trigger when either startDate or endDate changes

  useEffect(() => {
    fetch_admins_data(
      formatDate(startDate),
      formatDate(endDate),
      currentPage,
      pageLimit
    );
  }, [startDate, endDate, currentPage, pageLimit, selectedDashboard]); // Trigger when either startDate or endDate changes

  // const handleNextPage = () => {
  //   if (currentPage * pageLimit < totalRecords) {
  //     setCurrentPage((prev) => prev + 1);
  //   }
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage((prev) => prev - 1);
  //   }
  // };

  // const handleFirstPage = () => {
  //   setCurrentPage(1);
  // };

  // const handleLastPage = () => {
  //   setCurrentPage(Math.ceil(totalRecords / pageLimit));
  // };

  // const handlePageLimitChange = (e) => {
  //   setPageLimit(Number(e.target.value));
  //   setCurrentPage(1); // Reset to the first page
  // };

  console.log(adminGraph, "adminGraph");
  console.log(adminGraph.adminCount, "seriesGraph.adminCount");
  console.log("selectedDashboard",selectedDashboard);
  

  return (
    <div className="container-fluid tw-mb-16">
      <div className="tw-sticky tw-top-14 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full  tw-pb-3">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h4 className="tw-text-gray-600 tw-m-0">Engagement Analytics</h4>
            <div className="tw-w-[40%] tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4">
              <select
                value={selectedDashboard}
                onChange={(e) => setSelectedDashboard(e.target.value)}
                className="form-select tw-rounded-xl"
                style={{
                  width: "45%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                {/*<option value="all">All</option>
                 <option value="carenavigator">CareNavigator</option>
                <option value="memberconnect">MemberConnect</option>
                <option value="engagehub">EngageHub</option>
                <option value="medicaremanage">MediCareManage</option> */}
                 <option value="all">All</option>
                {dashboardList?.map((option, index) => (
                  <option key={index} value={option.dashboardId}>
                    {option.dashboardName}
                  </option>
                ))}
              </select>
              <CalendarDatePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png"
              alt="Invited Members icon"
              style={{ height: "140px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
              The Engagememt Analytics Dashboard provides key performance
              metrics for Engagement Admins and CareNavigators, including total
              conversations, responses, pending responses, and opt-outs. It
              features visualizations like wait time, average response time,
              opt-out trends, and template usage. A performance table with
              detailed metrics and tooltips helps team leads monitor efficiency
              and member engagement.
            </p>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px] tw-h-full">
              <div className="tw-p-2">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-relative">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                    {/* <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-absolute tw-top-0 tw-right-0 tw-font-semibold">
                      <i
                        className={`tw-font-bold bi ${
                          graphScale
                            ? "bi-arrow-up-right tw-text-green-500"
                            : "bi-arrow-down-right tw-text-red-500"
                        }`}
                      ></i>
                      <p className="tw-mb-0 tw-text-gray-500">(Percentage)%</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {(selectedDashboard === 'c3NjT1RON2UzRzI5dVVSOUJBSDhGdz09' || selectedDashboard === 'OWh0NSsrOXpEZU5POXEyL21Td2tMZz09' || selectedDashboard==='all') &&  (
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                All Responses
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Total number of CareNavigator responses
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : seriesGraph.cityMember &&
              seriesGraph.cityMember.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar2
                dataMonth={seriesGraph.cityMember}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
      {(selectedDashboard === 'c3NjT1RON2UzRzI5dVVSOUJBSDhGdz09' || selectedDashboard==='all') &&  (
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
              Average Response Time
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average response time by CareNavigator
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : seriesGraph.cnResponseData &&
              seriesGraph.cnResponseData.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={seriesGraph.cnResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      )}

{(selectedDashboard === 'OWh0NSsrOXpEZU5POXEyL21Td2tMZz09' || selectedDashboard==='all') && ( 
      <>
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by Enrollment Admin
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Time by Enrollment Admin
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : adminGraph.adminCount &&
              adminGraph.adminCount.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={adminGraph.adminResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by Enrollment Agent
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Time by Enrollment Agent
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : agentGraph.agentResponseData &&
            agentGraph.agentResponseData.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={agentGraph.agentResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      </>
)}
      {/* <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by Enrollment Campaign Manager
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average Response Time by Enrollment Campaign Manager
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : campaignGraph.campaignResponseData &&
            campaignGraph.campaignResponseData.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={campaignGraph.campaignResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* Template Breakdown Pie Chart */}
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-text-xl tw-font-semibold tw-text-gray-500 tw-animate-pulse">
                Loading...
              </div>
            ) : adminGraph.templateResult &&
              adminGraph.templateResult.length > 0 ? (
              <WhatsAppAnalyticsPie series={adminGraph.templateResult} />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-3 tw-gap-4">
        {/* WhatsApp Coordinator Responses Graph */}
        {/* <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                WhatsApp Engagement Admin Responses (2)
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      WhatsApp Engagement Admin response distribution
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : adminGraph.adminCount &&
              adminGraph.adminCount.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={adminGraph.adminCount}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div> */}

        {/* Average Response Time Graph */}
        {/* <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by WhatsApp Engagement Admin
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average response time by Engagement Admin
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : adminGraph.adminResponseData &&
              adminGraph.adminResponseData.dataValue.length > 0 ? (
              <WhatsAppAnalyticsBar
                dataMonth={adminGraph.adminResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
