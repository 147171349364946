import React, { createContext, useState, useContext } from "react";
const InboxContext = createContext();
export const useInboxContext = () => useContext(InboxContext);
export const InboxProvider = ({ children }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchMode, setSearchMode] = useState(false);
  const [filterMode, setFilterMode] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [family, setFamily] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("all");
  const [search, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("Newest");
  const [showkeyboard, setShowKeyBoard] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [optOut, setOptOut] = useState(false);


  const [chatPage, setChatpage] = useState(1);
  const [chatResult, setChatResult] = useState([]);
  const [loadingMore, setLoadingMore] = useState(true); // Loading state
  const [changeFilterState, setchangeFilterState] = useState(false); // 'search', 'filter', or 'selection'
  const [unreadCount, setUnreadCount] = useState(0); // 'search', 'filter', or 'selection'
  const [currentlySelectedContact, setCurrentlySelectedContact] = useState(
    null
  );
  const [initialMessageCount, setInitialMessageCount] = useState(0); // Track initial message count

  const [cnOptions, setCnOptions] = useState([]);
  const [selectedCN, setSelectedCN] = useState([]);
  const [cnFilter, setCnFilter] = useState(false);


  const resetSelectedContact = () => {
    setSelectedContact(null);
  };

  return (
    <InboxContext.Provider
      value={{
        contacts,
        selectedContact,
        setSelectedContact,
        searchMode,
        setSearchMode,
        filterMode,
        setFilterMode,
        selectionMode,
        setSelectionMode,
        selectedContacts,
        setSelectedContacts,
        currentPage,
        setCurrentPage,
        hasMore,
        loading,
        family,
        setFamily,
        status,
        setStatus,
        setContacts,
        search,
        setSearchTerm,
        sortOrder,
        setSortOrder,
        resetSelectedContact,
        setShowKeyBoard,
        chatResult,
        setChatResult,
        chatPage,
        setChatpage,
        loadingMore,
        setLoadingMore,
        setHasMore,
        setchangeFilterState,
        changeFilterState,
        setLoading,
        unreadCount,
        setUnreadCount,
        setCurrentlySelectedContact,
        currentlySelectedContact,
        showkeyboard,
        setInitialMessageCount,
        initialMessageCount,
        setTemplateStatus,
        templateStatus,
        setOptOut,
        optOut,
        cnOptions,
        setCnOptions,
        selectedCN,
        setSelectedCN,
        cnFilter,
        setCnFilter,
      }}
      className="tw-mb-16"
    >
      {children}
    </InboxContext.Provider>
  );
};
