import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import './TopBar.css'

const TopBar = (props) => {
    const active = (props.active) ? props.active : "";
    // const inbox = (props.inbox) ? props.inbox : "";
    const location = useLocation();
    const currentPath = location.pathname;
    const params = useParams();
    const userId = params.memberId;
    const handleTabClose = (user) => {
        props.closeUserTab(user);
    };
    // const inboxAccess = props.inboxAccess === true ? true : false;

    const [accessLevel, setAccessLevel] = useState(false);

    //const uniqueItems = [...new Set(props.userTabs)];

    function moveUserToFirstPlace(userId, usersArray) {
        const index = usersArray.findIndex(user => user.userId === userId);

        if (index > -1) {
            const [user] = usersArray.splice(index, 1);
            usersArray.unshift(user);
        }

        return usersArray;
    }


    let uniqueUsers = [...new Map(props.userTabs.map(user => [user.userId, user])).values()].reverse();

    uniqueUsers = moveUserToFirstPlace(userId, uniqueUsers);

    const topFewUsers = uniqueUsers.slice(0, 5);
    const remainingUsers = uniqueUsers.slice(5);

    const showRemainingUsers = remainingUsers.length > 0;


    useEffect(() => {
        const accessLevelInbox = localStorage.getItem("inboxAccess");
        
        setAccessLevel(accessLevelInbox);
        
    })
    

    return (
        <>
            <Row className='top-bar'>
                <Col className='px-0'>
                    <ul className=' topbar-d-flex'>
                        <li className={`${currentPath === "/dashboard" ? "active" : ""}`}><Link to={`/dashboard`}><i className="bi bi-grid-fill"></i> Dashboard</Link></li>
                        {parseInt(accessLevel) === 1 && <li className={`${currentPath === "/carenavigator-whatsapp-inbox" ? "active" : ""}`}><Link to={`/carenavigator-whatsapp-inbox`}><i className="bi bi-whatsapp"></i> Inbox</Link></li>}
                        <div className='top-bar-d-flex-box'>
                            {topFewUsers.map((user, index) => (

                                <li key={index} className={`topbar-d-flex-link ${user.userId === userId ? "active" : ""}`}>

                                    <div className='row align-items-center'>
                                        <Link className='text-truncate col' style={showRemainingUsers && topFewUsers.length === 5 ? { width: "13ch" } : {}} to={`/members/${user.userId}`}>{user.name}</Link>
                                        <i className="bi bi-x-lg cross-btn-closs col-auto" aria-hidden="true" onClick={() => handleTabClose(user)}></i>
                                    </div>

                                </li>

                            ))}
                        </div>
                        {
                            remainingUsers.length > 0 &&
                            <li className={`${active === "more" ? "active" : ""}`} style={{ position: "absolute", right: '0', paddingRight: "10px !important" }}>
                                <Link className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-people-fill"></i> More Members</Link>
                                <div className="btn-group">
                                    <ul className="dropdown-menu dropdown-menu-light dropdown-menu-end">
                                        {remainingUsers.map((user, index) => (
                                            <li key={index} className={`notification-item-border ${user.userId === userId ? "active" : ""}`}>
                                                <Link className='text-truncate' to={`/members/${user.userId}`}>{user.name}</Link>
                                                <i className="bi bi-x-lg cross-btn-closs" aria-hidden="true" onClick={() => handleTabClose(user)}></i>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                        }

                    </ul>

                </Col>
            </Row >
        </>
    )
}

export default TopBar