import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import alertify from 'alertifyjs';
import { Link } from 'react-router-dom';
import './MemberServices.css';
import { fetchHomeBasedServicesHistory, updateHomeBasedServiceStatus,fetchServiceLogHistory } from "../services/Service";
import { RenderStringWithBreaks } from '../../../utils/CommonLibrary';


const HomeBasedServicesHistory = forwardRef((props, ref) => {
    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [expandedRow, setExpandedRow] = useState(null);
    const memberBasicInfo = props.memberBasicInfo ? props.memberBasicInfo : {};
    const [serviceHistory, setServiceHistory] = useState([]);
    const [serviceLogs, setServiceLogs] = useState([]);
    const [fileUrl, setFileUrl] = useState('');
    

    const [hbsRequestDetail, setHbsRequestDetail] = useState({});

    const [showMr, setShowMr] = useState(false);
    const [showLr, setShowLr] = useState(false);
    const [showHcr, setShowHcr] = useState(false);
    const [showHbv, setShowHbv] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [showDetailModal, setShowDetailModal] = useState(false);

    useImperativeHandle(ref, () => ({
        getHomeBasedServicesHistory
    }));

    useEffect(() => {
        getHomeBasedServicesHistory();
    }, [userId])

    const getHomeBasedServicesHistory = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHomeBasedServicesHistory(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setServiceHistory(response.data.data);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const toggleRowExpansion = (index) => {
        setExpandedRow(prevState => (prevState === index ? null : index));
    };

    const handleDetailsModal = async (item, headerTitle, type,newType) => {
        setFileUrl('');
        setShowMr(false);
        setShowLr(false);
        setShowHcr(false);
        setShowHbv(false);
        setHbsRequestDetail(item);
        setModalHeader(headerTitle);
        if (type === "mr") {
            setShowMr(true);
        } else if (type === "lr") {
            setShowLr(true)
        } else if (type === "hcr") {
            setShowHcr(true)
        } else {
            setShowHbv(true)
        }

        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchServiceLogHistory(newType,item.id, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setServiceLogs(response.data.data.serviceLog);
                    setFileUrl(response.data.data.fileUrl);
                }
            }).catch(function (error) {
                console.log(error);
            });
        setShowDetailModal(true);
        return () => request.cancel();
    }

    const changeServiceStatus = async (e, serviceId, serviceType) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await updateHomeBasedServiceStatus(serviceId, serviceType, e.target.value, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getHomeBasedServicesHistory();
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    return (
        <>
            {
                ((serviceHistory.medications && serviceHistory.medications.length > 0) || (serviceHistory.labRequests && serviceHistory.labRequests.length > 0) || (serviceHistory.homeCareRequests && serviceHistory.homeCareRequests.length > 0) || (serviceHistory.homeBaseVaccines && serviceHistory.homeBaseVaccines.length > 0)) &&
                <div className='col-md-12 mt-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>Home Based Services History</h5>
                                        {
                                            (serviceHistory.medications && serviceHistory.medications.length > 0) &&
                                            <div className='table-responsive mt-3' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                        <tr>
                                                            <th scope="col" style={{ cursor: 'pointer' }} onClick={() => toggleRowExpansion(1)}>Medication Requests</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        expandedRow === 1 &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="3" className='pathways-table-expandable-td-bg p-0'>
                                                                    <div style={{ width: "98%" }}>
                                                                        <div className='row pathways-table-expandable'>
                                                                            <div className="col-12 pe-0">
                                                                                <div className="table-responsive" style={{ borderRadius: "0" }}>
                                                                                    <table className="table table-striped mb-0 p-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col"><h5 className="main-pathways-title mb-0" style={{fontSize:"16px"}}>Medications</h5></th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Remarks</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Pharmacy Name</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Requestor Name</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Date of Booking</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Action</th>
                                                                                                <th style={{ width: "5%" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                serviceHistory.medications.map((item, index) => {
                                                                                                    return <>
                                                                                                        <tr className=''>
                                                                                                            <td className="bg-td-green"><RenderStringWithBreaks inputString={item.medications} /></td>
                                                                                                            <td className='bg-td-green'>{item.remarks}</td>
                                                                                                            <td className='bg-td-green'>{item.pharmacyName}</td>
                                                                                                            <td className='bg-td-green'>{item.requestorName}</td>
                                                                                                            <td className='bg-td-green'>{item.createdTime}</td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                {
                                                                                                                    (item.status == "Completed" || item.requestFor == "metadoc") ? item.status :
                                                                                                                        <select class="service-action"
                                                                                                                            value={item.status}
                                                                                                                            onChange={e => changeServiceStatus(e, item.id, "mr")}
                                                                                                                        >
                                                                                                                            <option value="booked">Booked</option>
                                                                                                                            <option value="completed">Completed</option>
                                                                                                                        </select>
                                                                                                                }

                                                                                                            </td>
                                                                                                            <td className='bg-td-green'><i className="bi bi-eye btn health-locker-btn" onClick={() => handleDetailsModal(item, "Medication Request", "mr","med")}></i></td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                })
                                                                                            }
                                                                                        </tbody >
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }

                                                </table>
                                            </div>
                                        }

                                        {
                                            (serviceHistory.labRequests && serviceHistory.labRequests.length > 0) &&
                                            <div className='table-responsive mt-3' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                        <tr>
                                                            <th scope="col" style={{ cursor: 'pointer' }} onClick={() => toggleRowExpansion(2)}>Lab Requests</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        expandedRow === 2 &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="3" className='pathways-table-expandable-td-bg p-0'>
                                                                    <div style={{ width: "98%" }}>
                                                                        <div className='row pathways-table-expandable'>
                                                                            <div className="col-12 pe-0">
                                                                                <div className="table-responsive" style={{ borderRadius: "0" }}>
                                                                                    <table className="table table-striped mb-0 p-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col"><h5 className="main-pathways-title mb-0" style={{fontSize:"16px"}}>Requested Lab Tests</h5></th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Remarks</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Lab Name</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Requestor Name</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Date of Booking</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Action</th>
                                                                                                <th style={{ width: "5%" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                serviceHistory.labRequests.map((item, index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td className='bg-td-green'>{' '}{item.requestedLabTest}</td>
                                                                                                            <td className='bg-td-green'>{item.remarks}</td>
                                                                                                            <td className='bg-td-green'>{item.labName}</td>
                                                                                                            <td className='bg-td-green'>{item.requestorName}</td>
                                                                                                            <td className='bg-td-green'>{item.createdTime}</td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                {
                                                                                                                    (item.status == "Completed" || item.requestFor == "metadoc") ? item.status :
                                                                                                                        <select class="service-action"
                                                                                                                            value={item.status}
                                                                                                                            onChange={e => changeServiceStatus(e, item.id, "lr")}
                                                                                                                        >
                                                                                                                            <option value="booked">Booked</option>
                                                                                                                            <option value="completed">Completed</option>
                                                                                                                        </select>
                                                                                                                }

                                                                                                            </td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                <i className="bi bi-eye btn health-locker-btn" onClick={() => handleDetailsModal(item, "Lab Request", "lr","hbl")}></i>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody >
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }

                                                </table>
                                            </div>
                                        }


                                        {
                                            (serviceHistory.homeCareRequests && serviceHistory.homeCareRequests.length > 0) &&
                                            <div className='table-responsive mt-3' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                        <tr>
                                                            <th scope="col" style={{ cursor: 'pointer' }} onClick={() => toggleRowExpansion(3)}>Home Care Requests</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        expandedRow === 3 &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="3" className='pathways-table-expandable-td-bg p-0'>
                                                                    <div style={{ width: "98%" }}>
                                                                        <div className='row pathways-table-expandable'>
                                                                            <div className="col-12 pe-0">
                                                                                <div className="table-responsive" style={{ borderRadius: "0" }}>
                                                                                    <table className="table table-striped mb-0 p-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col"><h5 className="main-pathways-title mb-0" style={{fontSize:"16px"}}>Requested Services</h5></th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Remarks</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Provider Name</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Requestor Name</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Date of Booking</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Action</th>
                                                                                                <th style={{ width: "5%" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                serviceHistory.homeCareRequests.map((item, index) => {
                                                                                                    return <>
                                                                                                        <tr>
                                                                                                            <td className='bg-td-green'>{' '}{item.requestedHomeHealth}</td>
                                                                                                            <td className='bg-td-green'>{item.remarks}</td>
                                                                                                            <td className='bg-td-green'>{item.providerName}</td>
                                                                                                            <td className='bg-td-green'>{item.requestorName}</td>
                                                                                                            <td className='bg-td-green'>{item.createdTime}</td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                {
                                                                                                                    (item.status == "Completed" || item.requestFor == "metadoc") ? item.status :
                                                                                                                        <select class="service-action"
                                                                                                                            value={item.status}
                                                                                                                            onChange={e => changeServiceStatus(e, item.id, "hcr")}
                                                                                                                        >
                                                                                                                            <option value="booked">Booked</option>
                                                                                                                            <option value="completed">Completed</option>
                                                                                                                        </select>
                                                                                                                }

                                                                                                            </td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                <i className="bi bi-eye btn health-locker-btn" onClick={() => handleDetailsModal(item, "Health Care Request", "hcr","hhc")}></i>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                })
                                                                                            }
                                                                                        </tbody >
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }

                                                </table>
                                            </div>
                                        }

                                        {
                                            (serviceHistory.homeBaseVaccines && serviceHistory.homeBaseVaccines.length > 0) &&
                                            <div className='table-responsive mt-3' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                        <tr>
                                                            <th scope="col" style={{ cursor: 'pointer' }} onClick={() => toggleRowExpansion(4)}>Home Based Vaccines</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        expandedRow === 4 &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="3" className='pathways-table-expandable-td-bg p-0'>
                                                                    <div style={{ width: "98%" }}>
                                                                        <div className='row pathways-table-expandable'>
                                                                            <div className="col-12 pe-0">
                                                                                <div className="table-responsive" style={{ borderRadius: "0" }}>
                                                                                    <table className="table table-striped mb-0 p-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col"><h5 className="main-pathways-title mb-0" style={{fontSize:"16px"}}>Requested Service</h5></th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Vaccine</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Remarks</th>
                                                                                                <th scope="col" style={{ width: "20%" }}>Requestor Name</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Date of Booking</th>
                                                                                                <th scope="col" style={{ width: "15%" }}>Action</th>
                                                                                                <th style={{ width: "5%" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                serviceHistory.homeBaseVaccines.map((item, index) => {
                                                                                                    return <>
                                                                                                        <tr>
                                                                                                            <td className='bg-td-green'>{' '}{item.requestedHomeHealth}</td>
                                                                                                            <td className='bg-td-green'>{item.vaccine}</td>
                                                                                                            <td className='bg-td-green'>{item.remarks}</td>
                                                                                                            <td className='bg-td-green'>{item.requestorName}</td>
                                                                                                            <td className='bg-td-green'>{item.createdTime}</td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                {
                                                                                                                    (item.status == "Completed" || item.requestFor == "metadoc") ? item.status :
                                                                                                                        <select class="service-action"
                                                                                                                            value={item.status}
                                                                                                                            onChange={e => changeServiceStatus(e, item.id, "hbv")}
                                                                                                                        >
                                                                                                                            <option value="booked">Booked</option>
                                                                                                                            <option value="completed">Completed</option>
                                                                                                                        </select>
                                                                                                                }

                                                                                                            </td>
                                                                                                            <td className='bg-td-green'>
                                                                                                                <i className="bi bi-eye btn health-locker-btn" onClick={() => handleDetailsModal(item, "Home Based Vaccine", "hbv","hbv")}></i>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                })
                                                                                            }
                                                                                        </tbody >
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }

                                                </table>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
            <Modal id="hbsh" show={showDetailModal} centered onHide={() => setShowDetailModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title' style={{ width: "100%" }}>{modalHeader}</h1>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {
                            
                            showMr === true &&
                            <div>
                                <Row>
                                    <Col>
                                        <label>Membership</label><br />
                                        <span>{hbsRequestDetail.membershipNo}</span>
                                    </Col>
                                    <Col>
                                        <label>Name</label><br />
                                        <span>{hbsRequestDetail.name}</span>
                                    </Col>
                                    <Col>
                                        <label>Mobile</label><br />
                                        <span>{hbsRequestDetail.mobileNumber}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Partner Clinic</label><br />
                                        <span>{hbsRequestDetail.partnerName}</span>
                                    </Col>
                                    <Col>
                                        <label>Pharmacy</label><br />
                                        <span>{hbsRequestDetail.pharmacyName}</span>
                                    </Col>
                                    <Col>
                                        <label>Delivery/Refill</label><br />
                                        <span>{hbsRequestDetail.deliveryRefill}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>City</label><br />
                                        <span>{hbsRequestDetail.city}</span>
                                    </Col>
                                    <Col>
                                        <label>District</label><br />
                                        <span>{hbsRequestDetail.district}</span>
                                    </Col>
                                    <Col>
                                        <label>Deductible</label><br />
                                        <span>{hbsRequestDetail.deductible}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Remarks</label><br />
                                        <span>{hbsRequestDetail.remarks}</span>
                                    </Col>
                                    <Col>
                                        <label>Medications</label><br />
                                        <span><RenderStringWithBreaks inputString={hbsRequestDetail.medications} /></span>
                                    </Col>
                                    <Col>
                                        <label>Status</label><br />
                                        <span>{hbsRequestDetail.status}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Completion Date</label><br />
                                        <span>{hbsRequestDetail.completionDate}</span>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {
                            showLr === true &&
                            <div>
                                <Row>
                                    <Col>
                                        <label>Membership</label><br />
                                        <span>{hbsRequestDetail.membershipNo}</span>
                                    </Col>
                                    <Col>
                                        <label>Name</label><br />
                                        <span>{hbsRequestDetail.name}</span>
                                    </Col>
                                    <Col>
                                        <label>Mobile</label><br />
                                        <span>{hbsRequestDetail.mobileNumber}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Partner Clinic</label><br />
                                        <span>{hbsRequestDetail.partnerName}</span>
                                    </Col>
                                    <Col>
                                        <label>Lab Provider</label><br />
                                        <span>{hbsRequestDetail.labName}</span>
                                    </Col>
                                    <Col>
                                        <label>Approval Number</label><br />
                                        <span>{hbsRequestDetail.approvalNumber}</span>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <label>City</label><br />
                                        <span>{hbsRequestDetail.city}</span>
                                    </Col>
                                    <Col>
                                        <label>District</label><br />
                                        <span>{hbsRequestDetail.district}</span>
                                    </Col>
                                    <Col>
                                        <label>Nationality</label><br />
                                        <span>{hbsRequestDetail.nationality}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Deductible</label><br />
                                        <span>{hbsRequestDetail.deductible}</span>
                                    </Col>
                                    <Col>
                                        <label>Remarks</label><br />
                                        <span>{hbsRequestDetail.remarks}</span>
                                    </Col>
                                    <Col>
                                        <label>Requested Lab Tests</label><br />
                                        <span>{hbsRequestDetail.requestedLabTest}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Status</label><br />
                                        <span>{hbsRequestDetail.status}</span>
                                    </Col>
                                    {hbsRequestDetail.serviceDate && <Col>
                                        <label>Appointment Date</label><br />
                                        <span>{hbsRequestDetail.serviceDate}</span>
                                    </Col> }
                                    <Col>
                                        <label>Completion Date</label><br />
                                        <span>{hbsRequestDetail.completionDate}</span>
                                    </Col>
                                    {fileUrl.length > 0 &&
                                        <Col>
                                            <label>Download Report</label><br />
                                            <div className='DropDownAction'>
                                                <div className="btn-group dropend">
                                                    <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-download"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        {
                                                            fileUrl.map((item, index) => {
                                                                return <>
                                                                    <li className='list-class' key={index}><Link className="dropdown-item" target="_blank" to={item.link}>{item.name}</Link></li>
                                                                </>
                                                            })  
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }

                        {
                            showHcr === true &&
                            <div>
                                <Row>
                                    <Col>
                                        <label>Membership</label><br />
                                        <span>{hbsRequestDetail.membershipNo}</span>
                                    </Col>
                                    <Col>
                                        <label>Name</label><br />
                                        <span>{hbsRequestDetail.name}</span>
                                    </Col>
                                    <Col>
                                        <label>Mobile</label><br />
                                        <span>{hbsRequestDetail.mobileNumber}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Approval Number</label><br />
                                        <span>{hbsRequestDetail.approvalNumber}</span>
                                    </Col>

                                    <Col>
                                        <label>Partner Clinic</label><br />
                                        <span>{hbsRequestDetail.partnerClinic}</span>
                                    </Col>
                                    <Col>
                                        <label>Home Health Care Provider</label><br />
                                        <span>{hbsRequestDetail.providerName}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>City</label><br />
                                        <span>{hbsRequestDetail.city}</span>
                                    </Col>
                                    <Col>
                                        <label>District</label><br />
                                        <span>{hbsRequestDetail.district}</span>
                                    </Col>
                                    <Col>
                                        <label>Nationality</label><br />
                                        <span>{hbsRequestDetail.nationality}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Deductible</label><br />
                                        <span>{hbsRequestDetail.deductible}</span>
                                    </Col>
                                    <Col>
                                        <label>Home Care Service</label><br />
                                        <span>{hbsRequestDetail.requestedHomeHealth}</span>
                                    </Col>
                                    <Col>
                                        <label>Remarks</label><br />
                                        <span>{hbsRequestDetail.remarks}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Status</label><br />
                                        <span>{hbsRequestDetail.status}</span>
                                    </Col>
                                    {hbsRequestDetail.serviceDate && <Col>
                                        <label>Appointment Date</label><br />
                                        <span>{hbsRequestDetail.serviceDate}</span>
                                    </Col> }
                                    <Col>
                                        <label>Completion Date</label><br />
                                        <span>{hbsRequestDetail.completionDate}</span>
                                    </Col>
                                    {fileUrl.length > 0 &&
                                        <Col>
                                            <label>Download Report</label><br />
                                            <div className='DropDownAction'>
                                                <div className="btn-group dropend">
                                                    <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-download"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        {
                                                            fileUrl.map((item, index) => {
                                                                return <>
                                                                    <li className='list-class' key={index}><Link className="dropdown-item" target="_blank" to={item.link}>{item.name}</Link></li>
                                                                </>
                                                            })  
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }

                        {
                            showHbv === true &&
                            <div>
                                <Row>
                                    <Col>
                                        <label>Child Membership</label><br />
                                        <span>{hbsRequestDetail.membershipNo}</span>
                                    </Col>
                                    <Col>
                                        <label>Child Name</label><br />
                                        <span>{hbsRequestDetail.name}</span>
                                    </Col>
                                    <Col>
                                        <label>Mobile</label><br />
                                        <span>{hbsRequestDetail.mobileNumber}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>City</label><br />
                                        <span>{hbsRequestDetail.city}</span>
                                    </Col>
                                    <Col>
                                        <label>District</label><br />
                                        <span>{hbsRequestDetail.district}</span>
                                    </Col>
                                    <Col>
                                        <label>Nationality</label><br />
                                        <span>{hbsRequestDetail.nationality}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Vaccine</label><br />
                                        <span>{hbsRequestDetail.vaccine}</span>
                                    </Col>

                                    <Col>
                                        <label>Partner Clinic</label><br />
                                        <span>{hbsRequestDetail.partnerClinic}</span>
                                    </Col>
                                    <Col>
                                        <label>Home Health Care Service</label><br />
                                        <span>{hbsRequestDetail.requestedHomeHealth}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Deductible</label><br />
                                        <span>{hbsRequestDetail.deductible}</span>
                                    </Col>
                                    <Col>
                                        <label>Remarks</label><br />
                                        <span>{hbsRequestDetail.remarks}</span>
                                    </Col>
                                    <Col>
                                        <label>Status</label><br />
                                        <span>{hbsRequestDetail.status}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Completion Date</label><br />
                                        <span>{hbsRequestDetail.completionDate}</span>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {serviceLogs && <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                            <table className="table table-striped table-bordered mb-0">
                                <thead style={{ position: "sticky", top: "0" }}>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        serviceLogs?.map((item, index) => {
                                            return <>
                                                <tr style={{ verticalAlign: 'middle' }}>
                                                    <td className='bg-td-green'>{item.createdTime}</td>
                                                    <td className='bg-td-green'>{item.action}</td>
                                                </tr>
                                            </>
                                        })  
                                    }

                                </tbody>
                            </table>
                        </div> }
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
});

export default HomeBasedServicesHistory