import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { fetch_agent_enroll_later_approve_list, fetchProgramList } from '../../../services/met/Service';
import { time } from 'highcharts';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { CustomLoader } from '../utils/CommonLibrary';

function PastRequestRegisterLaterTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('');
    const [selectedCondition, setSelectedCondition] = useState('');

    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdTime");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    const [membersEnrollList, setMembersEnrollList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAgentMemberEnrollList();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, selectedProgram, selectedCondition, download]);


    useEffect(() => {
        fetchDropDownList();
    }, []);

    const fetchDropDownList = async () => {
        await fetchProgramList()
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }
    const fetchAgentMemberEnrollList = async () => {
        setIsLoading(true);
        try {
            let response = await fetch_agent_enroll_later_approve_list(searchQuery, selectedProgram, selectedCondition, page, perPage, sortColumn, sortDirection, download);
            if (response.data.code === 200) {
                if (download == "N") {
                    setMembersEnrollList(response.data.data.logs);
                    setTotalRows(response.data.data.totalRows);
                } else {
                    handleExport(response.data.data.logs);
                    setDownload('N');
                }
            }

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const formatDate = (inputDate) => {
        if (!inputDate) return '';
        const date = new Date(inputDate);

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        return formattedDate;
    }

    const columns = [
        {
            name: 'Request Created Date',
            selector: row => row.createdTime,
            format: row => row.createdTime,
            sortable: true,
        },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            format: row => row.agentName,
            sortable: false,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            format: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            format: row => row.mainMembershipNo,
            sortable: false,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            format: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Mobile No',
            selector: row => row.memberMobile,
            format: row => row.memberMobile,
            sortable: false,
        },

        {
            name: 'Program',
            selector: row => row.programName,
            format: row => row.programName,
            sortable: false,
        },
        {
            name: 'Condition',
            selector: row => row.conditionName,
            format: row => row.conditionName,
            sortable: false,
        },

        {
            name: 'CareNavigator Booking Date',
            selector: row => row.careBookingDate,
            format: row => row.careBookingDate,
            sortable: false,
        },
        {
            name: 'Care Navigator',
            selector: row => row.careNavigatorName,
            sortable: false,
        },
        {
            name: 'Action By',
            selector: row => row.actionBy,
            sortable: false,
        },

        {
            name: 'Action ',
            selector: row => row.approvedStatus,
            sortable: false,
        },

        {
            name: 'Action Date ',
            selector: row => row.approvedDate,
            sortable: false,
        },
    ];


    const handleStatusUpdate = async (id, type) => {
        // setIsLoading(true);
        // try {
        //     const payload = {
        //         logId: id,
        //         requestStatus: (type === 'approve' ? 'approved' : 'rejected')
        //     }
        //     const response = await updateMembersDisenroll(payload);

        //     if (response.data.status === 'success') {
        //         setMembersEnrollList((prev) => prev.filter((data) => data.logId !== id));

        //         setIsLoading(false);
        //     }
        // } catch (error) {
        //     console.log(error);
        //     setIsLoading(false);
        // }
    }

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('');
        setSelectedCondition('');
    };

    /*const newFilteredDataPast = membersEnrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.membershipNo.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesCondition = selectedCondition === 'All' || user.conditionName === selectedCondition;
        const matchesProgram = selectedProgram === 'All' || user.programName === selectedProgram;
        return matchesSearchQuery && matchesProgram && matchesCondition;
    });*/


    const handleExport = () => {

        const modifiedData = membersEnrollList.map(item => {
            return {
                'Request Created Date': item.createdTime,
                'Agent Name': item.agentName,
                'Member ID': item.membershipNo,
                'Main Membership No': item.mainMembershipNo,
                'Member Name': item.memberName,
                'Mobile No': item.memberMobile,
                'Program': item.programName,
                'Condition': item.conditionName,
                'CareNaviogator Booking Date': item.careBookingDate,
                'Care Navigator': item.conditionName,
                'Action By': item.actionBy,
                'Action ': item.approvedStatus,
                'Action Date': item.approvedDate,
            };
        });

        modifiedData.forEach(function (v) { delete v.approvedBy; delete v.approvedDate; delete v.logId; delete v.mobile; delete v.requestStatus; delete v.userId; });

        exportTableData("pastrequest_data", modifiedData);
    };

    return (
        <>
            {/* <LoaderLoader isLoading={isLoading} /> */}
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Programs</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value=""> Conditions</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={() => setDownload('Y')} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            {
                /*
                <DataTable
                columns={columns}
                data={newFilteredDataPast}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />*/
            }

            {
                isLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={membersEnrollList}
                        loading={isLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchAgentMemberEnrollList}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
        </>
    );
}

export default PastRequestRegisterLaterTable;
