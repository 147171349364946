import React from 'react'
import CnContactList from './CnContactList';
import CnChatArea from './CnChatArea';
import CnUserDetails from './CnUserDetails';
import { InboxProvider } from './CnInboxContext';
import TopBar from '../topbar/TopBar';
import { useUserTabs } from '../common/UserTabsContext';

const CnInbox = () => {

  const { userTabs, closeUserTab } = useUserTabs();

  return (
    <InboxProvider>
      <div className='mb-20 container-fluid'>
      <TopBar active="dashboard" userTabs={userTabs} closeUserTab={closeUserTab} />
      <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
  <div className="tw-w-full tw-pb-3 tw-pt-1">
    <div className="tw-flex tw-items-center tw-justify-between">
      <h4 className="tw-text-gray-600 tw-m-0 tw-font-semibold">Inbox</h4>
      
    </div>
  </div>
</div>
      <div className="tw-flex tw-h-[67vh] tw-p-1  tw-overflow-y-auto tw-bg-white tw-rounded-3xl tw-transition-all tw-duration-300">
        {/* Left Sidebar - Contact List */}
        <div className="tw-w-1/4 tw-border-r tw-border-gray-300 tw-relative tw-z-0">
          <CnContactList />
        </div>

        <div className='vr'></div>
        
        {/* Main Chat Area */}
        <div className="tw-w-1/2 tw-flex tw-flex-col">
          <CnChatArea/>
        </div>

        <div className='vr'></div>

        
        {/* Right Sidebar - User Details */}
        <div className="tw-w-1/4 tw-border-l tw-border-gray-300 tw-overflow-y-auto" >
          <CnUserDetails />
        </div>
      </div>
      </div>
    </InboxProvider>
  )
}
export default CnInbox