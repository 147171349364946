import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useUserTabs } from '../../components/common/UserTabsContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import './Notifications.css';
import { fetchNotifications, updateNotifications } from "../../../../services/cn/Service";

const Notifications = (props) => {
    const handleShowUnreadDot = props.handleShowUnreadDot ? props.handleShowUnreadDot : console.log("props.handleShowUnreadDot not found");

    const [notifications, setNotifications] = useState([]);
    const { addUserTab } = useUserTabs();

    const handleUserClick = (user) => {
        addUserTab(user);
    };

    useEffect(() => {
        getNotifications();
        const interval = setInterval(() => {
            getNotifications();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const getNotifications = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchNotifications(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setNotifications(response.data.data.notifications);
                    handleShowUnreadDot(response.data.data.showUnreadDot, response.data.data.unreadNotificationCount);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const updateNotificationAction = async (notificationId, userInfo, action) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await updateNotifications(notificationId, action, ct)
            .then((response) => {
                setNotifications([])
                if (action === "actiontaken") {
                    handleUserClick({ userId: userInfo.userId, name: userInfo.memberName });
                }
                //getNotifications();
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    return (

        <>
            {
                (notifications.length > 0) ?
                    <div className='notificationDiv'>
                        <Row>
                            <Col>
                                {notifications.map((notification, index) => (
                                    <div key={index} className='row align-items-center'>
                                        <div className='col-auto'>
                                            <span className='bellContainer'><i className="fa fa-bell" aria-hidden="true"></i></span>
                                        </div>
                                        <div className='col px-1 notificationTitle'>
                                            {notification.notificationText}
                                        </div>
                                        <div className='col-auto'>
                                            {
                                                <>
                                                    <span>
                                                        {' '}
                                                        <Link to={'/members/' + encodeURIComponent(notification.userId, "UTF-8")} className='name' onClick={() => {
                                                            updateNotificationAction(notification.notificationId, notification, 'actiontaken');

                                                        }}>{' '}
                                                            <button class="btn btn-primary save-btn">View Profile</button>
                                                        </Link>{' '}
                                                        <button class="btn notification-btn-closs ms-2" onClick={() => {
                                                            updateNotificationAction(notification.notificationId, notification, 'alertdismissed')
                                                        }}> <i className="bi bi-x-lg" aria-hidden="true"></i></button>

                                                    </span>
                                                </>
                                            }
                                        </div>



                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </div > : ""
            }

        </>

    );
};

export default Notifications;