import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsSolidGauge(Highcharts);

const ProgressbarEdu = () => {
  const percentage = 59; // Set your desired percentage here

  const options = {
    chart: {
      type: 'solidgauge',
      height: '100%', // Ensure the chart takes full height of the container
      backgroundColor: 'transparent',
      margin: [0, 0, 0, 0], // Remove all margins (top, right, bottom, left)
      events: {
        contextmenu: function (e) {
          e.preventDefault(); // Disable right-click context menu
        }
      }
    },
    title: {
      text: null
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [{
        outerRadius: '118%',  // Set outerRadius to 100% to use full width
        innerRadius: '100%',   // Set innerRadius to avoid too much empty space
        backgroundColor: '#d3d3d3',
        borderWidth: 0
      }]
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        rounded: true,
        borderWidth: 0
      }
    },
    credits: {
      enabled: false // Disable the credits button
    },
    exporting: {
      enabled: false // Disable the export button/menu
    },
    series: [{
      name: 'Progress',
      data: [{
        color: '#8dc73f',
        radius: '118%',  // Set to 100% to match the outerRadius
        innerRadius: '100%',  // Same as innerRadius
        y: percentage
      }]
    }]
  };

  const labelPosition = {
    top: `${50 - 42 * Math.cos((percentage / 100) * 2 * Math.PI)}%`,
    left: `${50 + 42 * Math.sin((percentage / 100) * 2 * Math.PI)}%`
  };

  return (
    <div style={{ position: 'relative', width: '100px', height: '100px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <div
        style={{
          position: 'absolute',
          ...labelPosition,
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#8dc73f',
          color: '#000',
          borderRadius: '50%',
          width: '34px',
          height: '34px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '8px',
          fontWeight:'600',
          padding:'10px',
        }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default ProgressbarEdu;
