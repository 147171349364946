import React, {useState, useEffect} from 'react';
import { fetch_member_callback_data, add_back_to_pool } from '../../../../../services/met/Service';
import { Modal, OverlayTrigger } from 'react-bootstrap';


const CallBackList = ({reflect, setReflect, tooltip}) => {


    const [callbackList, setCallbackList] = useState([]);
    const [readMore, setReadMore] = useState(false);
    const [readText, setReadText] = useState(""); 
    const [backToPoolConfirmationModal, setBacktoPoolConfirmationModal] = useState(false); 
    const [backtoPoolRecordInfo, setBacktoPoolRecordInfo] = useState({
      callbackId: "", 
      poolId:"",
      scheduledTime:""
    });
    

    useEffect(() => { 
        fetchCallbackList(); 
    }, [reflect]);

    async function fetchCallbackList() {
        try{ 
            let response = await fetch_member_callback_data(); 
            
            if(response?.data?.code === 200){
              let callbackData = response.data?.data?.callbacksData || [];
              setCallbackList(callbackData); 
            }

        }catch(e){  

        }
    }


    async function addBacktoPool(){ 
        try{ 
            let response = await add_back_to_pool(backtoPoolRecordInfo);

            if(response?.data?.code === 200){
              setBacktoPoolConfirmationModal(false);
              setBacktoPoolRecordInfo({ 
                callbackId: "", 
                poolId:""
              });
              setReflect(!reflect); 
              fetchCallbackList(); 
            } 
        }catch(e){ 
          
        }
    }


    function handleCloseBacktoPoolModal(){ 
      setBacktoPoolConfirmationModal(false); 
    }

    function confirmBackToPoolOperation(callbackId, poolId, userId, scheduledTime){
      
        setBacktoPoolRecordInfo({ 
          callbackId: callbackId, 
          poolId: poolId, 
          userId: userId,
          scheduledTime: scheduledTime
        });

        setBacktoPoolConfirmationModal(true);
    }


  return (
    <div className="col-md-12 mt-4 mb-5">
      <div className="card card-box-agent-white">
        <div className="card-body">
          <div className="row align-items-center my-1">
            <div className="col">
              <h2 className="agent-card-subtitle">Scheduled Callbacks 
                 <OverlayTrigger placement="top" overlay={tooltip({text: "A list of callbacks marked today with scheduled times and member follow-up details. Includes the option to add a member to the pool again directly from the scheduled callback list using the 'Add to Pool' button."})}>
                    <i className='bx bx-info-circle ms-1'></i>
                </OverlayTrigger>

              </h2>
              <audio id="remote-audio" autoPlay></audio>
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-12">        
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Member ID</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">Call back Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Call back Notes</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    callbackList.length == 0 ?
                    <tr>
                      <td>No Data Available</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    :
                    callbackList.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.memberId}</td>
                          <td>{item.memberName}</td>
                          <td>{item.scheduledTime}</td>
                          <td>{item.time}</td>
                          <td>{item?.notes?.length > 50 ? <span> {item?.notes?.slice(0, 50)}... <a href="javascript:void(0)" onClick={() => {setReadMore(true); setReadText(item?.notes)}}>Read More</a></span> : item?.notes?.length === 0 ? "-" : item?.notes}</td>
                          <td><button className='btn btn-primary btn-sm call-now-btn' onClick={() => confirmBackToPoolOperation(item.id, item.poolId, item.userId, item.scheduledTime)} >Add to Pool</button></td>
                        </tr>   
                      );
                    })
                  }
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/*  back to pool confirmation Modal */}

      <Modal id="backToPool" show={backToPoolConfirmationModal} onHide={() => handleCloseBacktoPoolModal()} centered size="lg" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton center>
        </Modal.Header>
        <Modal.Body>
          <div className='row text-center'>
              <p style={{fontSize: "18px"}}>Callback set for {backtoPoolRecordInfo?.scheduledTime} will be reset if the member is added to the pool. Proceed?</p>
              
              
              <div>
                  <button className='btn btn-dark coach-call-enroll-btn' onClick={e => addBacktoPool(e)}>Yes</button>
                  <button className='btn btn-secondary tw-ml-3' onClick={e => handleCloseBacktoPoolModal()}>No</button>

              </div>
          </div>
        </Modal.Body> 
      </Modal>

       {/* Read More Modal */}
       <Modal id="readMore" show={readMore} onHide={() => setReadMore(false)} centered size="lg" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton center>
        </Modal.Header>
        <Modal.Body>
          <div className='row text-center'>
              <p>{readText}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CallBackList;
