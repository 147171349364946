import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { whatsAppCreateCampaign } from '../../../services/mtp/Service';
import { filterUserDataDownload } from '../../../services/whatsapp/Service';

const SetMessageLive = ({
  campaignName,
  setCampaignName,
  scheduling,
  dateTime,
  selectedTemplate,
  setSelectedTemplate,
  selectedFilteredList,
  setSelectedFilteredList,
  totalCountUserList,
  setTotalCountUserList,
  memberFilterSelectQuery,
  setMemberFilterSelectQuery,
  filterQueries,
  optOutNCount,
  setOptOutNCount,
  optOutYCount,
  setOptOutYCount,
  setIsLoading,
  isLoading,
  targetNumber
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localState, setLocalState] = useState({
    totalCount: totalCountUserList,
    optOutN: optOutNCount,
    optOutY: optOutYCount,
    totalReaminingCount: totalCountUserList- optOutYCount -targetNumber,
    filterQuery: memberFilterSelectQuery
  });
  
  const navigate = useNavigate();

  // Update local state when props change
  useEffect(() => {
    setLocalState({
      totalCount: totalCountUserList,
      optOutN: optOutNCount,
      optOutY: optOutYCount,
      totalReaminingCount:totalCountUserList-optOutYCount-targetNumber,
      filterQuery: memberFilterSelectQuery
    });
  }, [totalCountUserList, optOutNCount, optOutYCount, memberFilterSelectQuery]);

  // Handle ESC key press to close modal
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) setIsModalOpen(false);
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  // Reset messages when relevant props change
  useEffect(() => {
    setErrorMessage('');
    setSuccessMessage('');
  }, [campaignName, selectedFilteredList, memberFilterSelectQuery, scheduling, dateTime, selectedTemplate]);

  const validateForm = () => {
    if (!campaignName) {
      setErrorMessage('Campaign Name is required.');
      return false;
    }

    if (!selectedFilteredList || selectedFilteredList.length === 0 || memberFilterSelectQuery === '') {
      setErrorMessage('Please select at least one filter');
      return false;
    }

    if (!scheduling) {
      setErrorMessage('Scheduling is required.');
      return false;
    }

    if (!dateTime) {
      setErrorMessage('Date and Time are required.');
      return false;
    }

    if (!selectedTemplate) {
      setErrorMessage('A template must be selected.');
      return false;
    }

    return true;
  };

  const finalSubmitCampaign = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    
    if (!validateForm()) return;

    let totalFitlerCount;
    setIsLoading(true);
    if (optOutNCount > 0) {
      if (optOutYCount > localState.totalCount) {
        totalFitlerCount = targetNumber - optOutYCount
      } else if (targetNumber > localState.totalCount) {
        totalFitlerCount = localState.totalCount - optOutYCount;
      } else {
        totalFitlerCount = targetNumber;
      }
    } else {
      totalFitlerCount = 0; // Default value if optOutNCount is not greater than 0
    }
    //(localState.optOutY > localState.totalCount) ? (targetNumber - localState.optOutY) : ((targetNumber > localState.totalCount ) ? (localState.totalCount - localState.optOutY) : (targetNumber)
//{(localState.optOutN > targetNumber) ? (targetNumber) : ((localState.optOutN > localState.totalCount ) ? (localState.totalCount - localState.optOutY) : ((localState.totalCount > localState.optOutN ) ? (localState.totalCount - localState.optOutN) : (localState.totalCount - localState.optOutY)))}
    try {
      const data = {
        campaignName,
        scheduling,
        dateTime,
        selectedTemplate,
        memberFilterSelectQuery,
        totalCountUserList:totalFitlerCount,
        filterQueries,
      };
    
      const response = await whatsAppCreateCampaign(JSON.stringify(data));  
      if (response.data.code === 200) {
        setSuccessMessage(response.data.data.message);
        // Reset all form fields
        setSelectedFilteredList([]);
        setTotalCountUserList('');
        setOptOutNCount('');
        setOptOutYCount('');
        setMemberFilterSelectQuery('');
        setSelectedTemplate(null);
        setCampaignName('');
        navigate('/notification-dashboard');
      } else {
        setErrorMessage(response.data.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage('An error occurred while creating the campaign. Please try again.');
      console.error('Campaign creation error:', error);
    }
  };

  const filterDataDownload = async (userStatus,numbers) => {
    try {
      setIsLoading(true);
      const response = await filterUserDataDownload(memberFilterSelectQuery, userStatus, numbers);
      const blob = new Blob([response.data], { type: 'text/csv' });
      
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', campaignName ? `${campaignName}.csv` : 'data.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
      
      if (response.data.code === 200) {
        console.log("download", response.data.data);
      } else {
        console.log("No record found");
      }
    } catch (error) {
      console.error('Download error:', error);
      setErrorMessage('An error occurred while downloading the data.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tw-p-4">
      <h3 className="tw-text-xl tw-font-bold tw-mb-4">Are you sure you are ready?</h3>

      {localState.totalCount > 0 && (
        <div className="tw-mb-4 tw-bg-gray-100 tw-p-4 tw-rounded-xl">
          <p className="tw-mb-2 tw-flex tw-flex-row tw-items-center">
            The campaign will be set for {(localState.optOutY > localState.totalCount) ? (targetNumber - localState.optOutY) : ((targetNumber > localState.totalCount ) ? (localState.totalCount - localState.optOutY) : (targetNumber))} members.&nbsp;
            {(localState.totalCount > 0 && localState.optOutN !== 0) && (
            <button 
              type="button" 
              onClick={() => filterDataDownload('N',(localState.optOutY > localState.totalCount) ? (targetNumber) : ((targetNumber > localState.totalCount ) ? (localState.totalCount - localState.optOutY) : (targetNumber)))}
              className="dropdown-item btns-downloads ms-2 btn btn-primary"
              disabled={isLoading}
            >
              <i className="bi bi-download"></i>
            </button>
            )}
          </p>
          
          {localState.optOutY > 0 && (
            <p className="tw-mb-2 tw-flex tw-flex-row tw-items-center">
              A total of {localState.totalCount} members were filtered, however, {localState.optOutY} members cannot receive this message.&nbsp;
              {(localState.optOutY > 0) && (
              <button 
                type="button" 
                onClick={() => filterDataDownload('Y',localState.optOutY)}
                className="dropdown-item btns-downloads ms-2 btn btn-primary"
                disabled={isLoading}
              >
                <i className="bi bi-download"></i>
              </button>
              )}
            </p>
          )}
          {localState.optOutN > targetNumber && (
            <p className="tw-mb-2 tw-flex tw-flex-row tw-items-center">
              Daily limit of {targetNumber} messages reached. {localState.totalReaminingCount} members cannot receive messages. Try again tomorrow or contact admin.
            <button 
                type="button" 
                onClick={() => filterDataDownload('limit',(localState.optOutN > targetNumber) ? (localState.totalReaminingCount) : (localState.optOutN))}
                className="dropdown-item btns-downloads ms-2 btn btn-primary"
                disabled={isLoading}
              >
                <i className="bi bi-download"></i>
              </button>
            </p>
          )}
          
          <span className="tw-text-gray-500">
            Click <span onClick={() => setIsModalOpen(true)} className="tw-text-[#0d638f] tw-cursor-pointer">here</span> to know more information on message delivery
          </span>
        </div>
      )}

      {isModalOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <div className="tw-bg-white tw-rounded-2xl tw-relative tw-max-w-2xl tw-w-full tw-mx-4 tw-max-h-[75vh] tw-overflow-y-auto no-scrollbar">
            <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-mb-4 tw-sticky tw-top-0 tw-bg-white">
              <h2 className="tw-text-xl tw-font-semibold tw-text-gray-600">
                Important Information: Message Delivery Limitations
              </h2>
              <span 
                onClick={() => setIsModalOpen(false)}
                className="tw-text-gray-500 tw-cursor-pointer tw-font-light hover:tw-text-gray-700 tw-text-5xl tw-mb-4"
              >
                ×
              </span>
            </div>
            
            <div className="tw-space-y-4 tw-px-4">
              <p className="tw-text-gray-700">
                Please be aware that message delivery may still fail due to several factors, including:
              </p>
              <ul className="tw-space-y-2 tw-list-decimal tw-pl-6">
                <li><span className="tw-text-gray-600">Member Not on WhatsApp:</span> The mobile number registered is not linked to an active WhatsApp account.</li>
                <li><span className="tw-text-gray-600">Opted Out of WhatsApp Communication:</span> The member has opted out and chosen not to receive WhatsApp messages.</li>
                <li><span className="tw-text-gray-600">Meta Restrictions:</span> Meta may choose not to deliver the message if the member has already received a message from another business within a short period.</li>
                <li><span className="tw-text-gray-600">Future-Scheduled Campaigns:</span> EngageHub will check the messaging limit at the time the campaign is set to start.</li>
                <li><span className="tw-text-gray-600">Phone Number Issues:</span> The mobile number provided is invalid, disconnected, or has other technical issues.</li>
                <li><span className="tw-text-gray-600">WhatsApp Account Restrictions:</span> The member's WhatsApp account may have settings that limit message delivery.</li>
                <li><span className="tw-text-gray-600">Network Issues:</span> Temporary network or connectivity issues may cause delivery failure.</li>
                <li><span className="tw-text-gray-600">Message Blocked by Member:</span> The member may have blocked the business number on WhatsApp.</li>
                <li><span className="tw-text-gray-600">Exceeded Daily Limit:</span> If the business account has exceeded its message quota, delivery may be restricted.</li>
              </ul>
              <p className="tw-text-gray-700 tw-mt-4">
                For optimal message delivery, consider these factors when scheduling campaigns.
              </p>
            </div>
          </div>
        </div>
      )}

      <p className="tw-text-gray-600 tw-mb-6">
        Please make sure you have confirmed all the details before hitting the button.
        Once started, a campaign cannot be stopped midway and this might lead to unnecessary expenses at your end.
      </p>

      {errorMessage && (
        <p className="tw-text-red-600 tw-mb-4">{errorMessage}</p>
      )}
      {successMessage && (
        <p className="tw-text-green-600 tw-mb-4">{successMessage}</p>
      )}

      {(localState.totalCount > 0 && localState.optOutN !== 0) && (
        <div className="tw-flex tw-space-x-4">
          <button 
            className="btn btn-success uplaod-file-btn" 
            onClick={finalSubmitCampaign}
            disabled={isLoading}
          >
            Set live
          </button>
        </div>
      )}
    </div>
  );
};

export default SetMessageLive;